import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  width: 100%;
  padding: 2rem 1rem;
  background: transparent;
  margin-top: 4rem;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    padding: 1.5rem 1rem;
    margin-top: 3rem;
  }
  
  @media (max-width: 480px) {
    padding: 1rem;
    margin-top: 2rem;
  }
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
  
  @media (max-width: 768px) {
    gap: 0.8rem;
  }
  
  @media (max-width: 480px) {
    gap: 0.6rem;
  }
`;

const ContactEmail = styled.a`
  color: #333;
  font-size: 1.2rem;
  text-decoration: none;
  transition: color 0.3s ease;
  font-family: 'Press Start 2P', cursive;
  padding: 0.5rem;
  
  &:hover {
    color: #FFDE4D;
  }
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
  
  @media (max-width: 480px) {
    font-size: 0.8rem;
    padding: 0.3rem;
  }
`;

const Copyright = styled.p`
  color: #666;
  font-size: 1rem;
  margin: 0;
  padding: 0 1rem;
  line-height: 1.5;
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
  
  @media (max-width: 480px) {
    font-size: 0.8rem;
    padding: 0 0.5rem;
  }
`;

const Divider = styled.div`
  width: 50px;
  height: 2px;
  background-color: #FFDE4D;
  margin: 1rem 0;
  
  @media (max-width: 768px) {
    width: 40px;
    margin: 0.8rem 0;
  }
  
  @media (max-width: 480px) {
    width: 30px;
    height: 1px;
    margin: 0.6rem 0;
  }
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <FooterContent>
        <ContactEmail href="mailto:cath.live.contact@gmail.com">
          Email us
        </ContactEmail>
        <Divider />
        <Copyright>
          © {currentYear} Morpheus Technology Pty Ltd. All rights reserved.
        </Copyright>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer; 