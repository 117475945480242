import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import DownloadSection from "./DownloadSection";

// Styled-components for container, iframe, and overlay
const BannerContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  @media (max-width: 768px) {
    height: calc(100vh - 60px); // Adjust for mobile header
    width: 100%;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
  }
`;

const MapFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  z-index: 1;
`;

// Fade-in animation for the overlay
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
  // background: rgba(0, 0, 0, 0.7);
  color: #2563eb;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  z-index: 2;
  animation: ${fadeIn} 1s ease-in-out;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  @media (max-width: 1024px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 90%;
    padding: 15px;
    justify-content: center;
    gap: 2rem;
  }
`;

const TypingText = styled.div`
  font-family: "Press Start 2P", cursive;
  font-size: 30px;
  line-height: 1.5;
  white-space: pre-wrap; /* Preserve line breaks */
  @media (max-width: 1024px) {
    font-size: 24px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 1.8;
  }
  @media (max-width: 480px) {
    font-size: 16px;
    line-height: 2;
  }
`;

const Heading = styled.h2`
  margin-bottom: 3rem;
  font-family: 'Press Start 2P', cursive;

  font-size: 3rem;
  @media (max-width: 1024px) {
    font-size: 40px;
    margin-bottom: 2rem;
  }
  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 1.5rem;
  }
  @media (max-width: 480px) {
    font-size: 24px;
    margin-bottom: 1rem;
  }
`;

const BottomBlur = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px; /* Adjust the height of the blur effect */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  pointer-events: none; /* Allow clicks to pass through the blur */
  z-index: 1; /* Place blur beneath the content */
  @media (max-width: 768px) {
    height: 150px; // Smaller blur height on mobile
  }
`;

// Main Component
const MapBanner = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [typedText, setTypedText] = useState("");

  const fullText =
    "EXPLORE placed gems by others, artworks OR PLACE your stuffs for others to find, real world PLAY-TO-EARN is real.";

  // Typing effect logic
  useEffect(() => {
    let showTimeout, typingTimeout;
    let currentText = ""; // Local variable to hold typed text

    const typeText = () => {
      let index = 0;

      const type = () => {
        currentText += fullText[index]; // Append the next character
        setTypedText(currentText); // Update the state with the local text
        index++;
        if (index < fullText.length) {
          typingTimeout = setTimeout(type, 50); // Delay between each character
        }
      };
      type();
    };

    // Show overlay after 10 seconds
    showTimeout = setTimeout(() => {
      setShowOverlay(true);
      typeText();
    }, 3000);

    // Hide overlay after 30 seconds
    // const hideTimeout = setTimeout(() => {
    //   setShowOverlay(false);
    //   setTypedText(""); // Clear text when overlay hides
    // }, 40000);

    // Cleanup timeouts
    return () => {
      clearTimeout(showTimeout);
      // clearTimeout(hideTimeout);
      clearTimeout(typingTimeout);
    };
  }, []);

  return (
    <BannerContainer>
      <MapFrame
        src="https://api.mapbox.com/styles/v1/cahunter/cm3p9azqv000701sne3rn1b4c.html?title=false&access_token=pk.eyJ1IjoiY2FodW50ZXIiLCJhIjoiY20zcDk3MWptMGQ2OTJyb2FjcWVodXcwbyJ9.KpVXU9HKAb6zv5As2_1BuQ&zoomwheel=false#15.99/-33.856471/151.211084/188/71"
        title="Map Background"
        allowFullScreen={false}
      />

      {/* Conditional rendering of the overlay */}
      {showOverlay && (
        <Overlay>
          <Heading>STOP PVP & BEING STRESSFUL</Heading>
          <Heading>GO OUT & PLAY</Heading>
          <TypingText>{typedText}</TypingText>
          <DownloadSection />

        </Overlay>
        
      )}
      <BottomBlur />
    </BannerContainer>
  );
};

export default MapBanner;