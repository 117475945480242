import React from 'react'
import styled from 'styled-components';
import find from "../../assets/images/find.png"
import place from "../../assets/images/place.png"
const CoreContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Press Start 2P', cursive;
  padding: 2rem;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const CoreTitle = styled.h2`
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
  
  @media (max-width: 1200px) {
    font-size: 4rem;
  }
  
  @media (max-width: 768px) {
    font-size: 3rem;
  }
  
  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 4rem;
  margin-top: 2rem;
  width: 80%;
  
  @media (max-width: 1200px) {
    width: 90%;
    gap: 3rem;
  }
  
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 2rem;
  }
`;

const ActionBox = styled.div`
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  flex: 1;
  
  @media (max-width: 1200px) {
    padding: 1.5rem;
  }
  
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const ActionTitle = styled.h3`
  font-size: 2rem;
  margin-bottom: 1rem;
  
  @media (max-width: 1200px) {
    font-size: 1.75rem;
  }
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  
  @media (max-width: 480px) {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }
`;

const ActionDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  font-family: monospace !important;
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
    line-height: 1.4;
    margin-bottom: 1rem;
  }
  
  @media (max-width: 480px) {
    font-size: 0.8rem;
    line-height: 1.3;
  }
`;

const CoreDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  margin: 1.5rem 0;
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
    line-height: 1.4;
    margin: 1rem 0;
  }
  
  @media (max-width: 480px) {
    font-size: 0.8rem;
    line-height: 1.3;
  }
`;

const ActionImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: contain;
  transform: perspective(800px) rotateY(15deg) skew(-2deg);
  transition: transform 0.3s ease;
  
  @media (hover: hover) {
    &:hover {
      transform: perspective(800px) rotateY(-15deg) skew(2deg) scale(1.05);
    }
  }
  
  @media (max-width: 1200px) {
    max-height: 350px;
  }
  
  @media (max-width: 768px) {
    max-height: 300px;
    transform: perspective(800px) rotateY(10deg) skew(-1deg);
    
    &:hover {
      transform: perspective(800px) rotateY(-10deg) skew(1deg) scale(1.03);
    }
  }
  
  @media (max-width: 480px) {
    max-height: 250px;
    transform: perspective(800px) rotateY(5deg) skew(-0.5deg);
    
    &:hover {
      transform: perspective(800px) rotateY(-5deg) skew(0.5deg) scale(1.02);
    }
  }
`;

const Core = () => {
  return (
    <CoreContainer>
      <CoreTitle>Two Actions</CoreTitle>
      <CoreDescription>Single player mode || Group mode || PVP mode</CoreDescription>
      <ActionsContainer>
        <ActionBox>
          <ActionTitle>Find</ActionTitle>
          <ActionDescription>
            Search for hidden treasures in the real world
          </ActionDescription>
          <ActionImage src={find} alt="Explore" />
        </ActionBox>
        <ActionBox>
          <ActionTitle>Place</ActionTitle>
          <ActionDescription>
            Place stuff in every corner of the world
          </ActionDescription>
          <ActionImage src={place} alt="Place" />
        </ActionBox>
      </ActionsContainer>
    </CoreContainer>
  )
}

export default Core