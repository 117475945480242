// Import Firebase SDK
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyBf2ypIIOgTi0BVMCGA_r9ErqooL4vePdM",
  authDomain: "cath-f12dd.firebaseapp.com",
  projectId: "cath-f12dd",
  storageBucket: "cath-f12dd.appspot.com",
  messagingSenderId: "721153854078",
  appId: "1:721153854078:web:b0cb94b56a85989e7c262f",
  measurementId: "G-1Z008EFFEF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics only in browser environment
let analytics = null;
if (typeof window !== 'undefined') {
  analytics = getAnalytics(app);
}

// Initialize Firestore
const db = getFirestore(app);

// Export the initialized instances
export { app, analytics, db };