import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import cityart1 from '../../assets/images/cityart1.jpg';
import cityart2 from '../../assets/images/cityart2.jpg';
import cityart3 from '../../assets/images/cityart3.jpg';
import cityart4 from '../../assets/images/cityart4.jpg';
import cityart5 from '../../assets/images/cityart5.jpg';

const CityArtContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  font-family: 'Press Start 2P', cursive;
  box-sizing: border-box;
`;

const PhotoWall = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translate(
    ${props => props.scrollOffset * 0.3}px,
    ${props => props.scrollOffset}px
  );
  transition: transform 0.1s ease-out;
`;

const Photo = styled.img`
  position: absolute;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  left: ${props => props.left}%;
  top: ${props => props.top}%;
  transition: transform 0.3s ease;
  cursor: pointer;
  z-index: ${props => props.zIndex};

  @media (max-width: 1200px) {
    width: ${props => props.width * 0.8}px;
    height: ${props => props.height * 0.8}px;
  }

  @media (max-width: 768px) {
    width: ${props => props.width * 0.6}px;
    height: ${props => props.height * 0.6}px;
  }

  @media (max-width: 480px) {
    width: ${props => props.width * 0.4}px;
    height: ${props => props.height * 0.4}px;
  }

  @media (hover: hover) {
    &:hover {
      transform: scale(1.1);
      z-index: 10;
    }
  }

  @media (hover: none) {
    &:active {
      transform: scale(1.05);
      z-index: 10;
    }
  }
`;

const CityArtText = styled.div`
  position: absolute;
  top: 25%;
  left: 25%;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 50%;
  height: auto;
  padding: 2rem;
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
  box-sizing: border-box;
  max-width: calc(100% - 4rem);

  
  @media (max-width: 1200px) {
    width: 70%;
    left: 15%;
    padding: 1.8rem;
    margin: 1.8rem;
    gap: 1.2rem;
    max-width: calc(100% - 3.6rem);
  }
  
  @media (max-width: 768px) {
    width: 85%;
    left: 7.5%;
    top: 15%;
    padding: 1.5rem;
    margin: 1.5rem;
    gap: 1rem;
    max-width: calc(100% - 3rem);
  }
  
  @media (max-width: 480px) {
    width: 92%;
    left: 4%;
    top: 10%;
    padding: 1rem;
    margin: 1rem;
    gap: 0.8rem;
    max-width: calc(100% - 2rem);
  }

  // Add safe area insets for notched devices
  @supports
`;

const Heading = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
  
  .join {
    color: #ccc498;
    font-size: 3rem;
    margin-right: 1.5rem;
  }
  
  @media (max-width: 1200px) {
    font-size: 1.8rem;
    .join {
      font-size: 2.7rem;
      margin-right: 1.2rem;
    }
  }
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
    .join {
      font-size: 2.2rem;
      margin-right: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    font-size: 1.2rem;
    .join {
      font-size: 1.8rem;
      margin-right: 0.8rem;
    }
  }
`;

const ExampleText = styled.p`
  font-size: 1rem;
  font-family: monospace !important;
  color: #4b5563;
  text-align: center;
  line-height: 1.5;
  
  @media (max-width: 1200px) {
    font-size: 0.9rem;
    line-height: 1.4;
  }
  
  @media (max-width: 768px) {
    font-size: 0.8rem;
    line-height: 1.3;
  }
  
  @media (max-width: 480px) {
    font-size: 0.7rem;
    line-height: 1.2;
  }
`;

const Highlight = styled.p`
  font-size: 1.5rem;
  color: red;
  text-align: center;
  line-height: 1.5;
  
  @media (max-width: 1200px) {
    font-size: 1rem;
    line-height: 1.4;
  }
  
  @media (max-width: 768px) {
    font-size: 1.1rem;
    line-height: 1.3;
  }
  
  @media (max-width: 480px) {
    font-size: 0.9rem;
    line-height: 1.2;
  }
`;

const CityArt = () => {
  const [scrollOffset, setScrollOffset] = useState(0);
  const [photos, setPhotos] = useState([]);
  const cityArtRef = useRef(null);

  const images = [cityart1, cityart2, cityart3, cityart4, cityart5];

  useEffect(() => {
    const gridSections = [
      { xMin: 0, xMax: 33, yMin: 0, yMax: 33 },      // Top left
      { xMin: 33, xMax: 66, yMin: 0, yMax: 33 },     // Top center
      { xMin: 66, xMax: 100, yMin: 0, yMax: 33 },    // Top right
      { xMin: 15, xMax: 45, yMin: 33, yMax: 66 },    // Bottom left
      { xMin: 55, xMax: 85, yMin: 33, yMax: 66 },    // Bottom right
    ];

    const getRandomPosition = (section) => {
      const padding = 5;
      return {
        left: Math.random() * (section.xMax - section.xMin - padding * 2) + section.xMin + padding,
        top: Math.random() * (section.yMax - section.yMin - padding * 2) + section.yMin + padding
      };
    };

    const photoPositions = Array.from({ length: 5 }, (_, index) => {
      const section = gridSections[index];
      const position = getRandomPosition(section);
      
      const baseWidth = Math.random() * (300 - 150) + 150;
      const aspectRatio = 1.5;
      
      return {
        id: index,
        src: images[index],
        left: position.left,
        top: position.top,
        width: baseWidth,
        height: baseWidth * aspectRatio,
        zIndex: Math.floor(Math.random() * 3)
      };
    });

    const shuffledPhotos = [...photoPositions].sort(() => Math.random() - 0.5);
    setPhotos(shuffledPhotos);
  }, []);

  useEffect(() => {
    const handleScroll = (e) => {
      setScrollOffset(prev => {
        const newOffset = prev + e.deltaY * 0.2;
        return Math.min(Math.max(newOffset, -100), 100);
      });
    };

    window.addEventListener('wheel', handleScroll);
    return () => window.removeEventListener('wheel', handleScroll);
  }, []);

  return (
    <CityArtContainer id="ca" ref={cityArtRef}>
      <PhotoWall scrollOffset={scrollOffset}>
        {photos.map(photo => (
          <Photo
            key={photo.id}
            src={photo.src}
            left={photo.left}
            top={photo.top}
            width={photo.width}
            height={photo.height}
            zIndex={photo.zIndex}
            alt={`Photo ${photo.id}`}
          />
        ))}
      </PhotoWall>
      <CityArtText>
        <Heading><span className='join'>X </span>City Artists (CA)</Heading>
        <ExampleText>The real CA: Join the city renovation revolution! </ExampleText>
        <ExampleText>All renovation spots have digital items to collect! Go Explore now! </ExampleText>
        <ExampleText>Design, Inspiration, Paint, 3D Print and any other creative work will be rewarded! Go DepoyL the real CA now! </ExampleText>
        <Highlight>Partnership is under negotiation with a bunch of artists and designers! Coming Soon</Highlight>
      </CityArtText>
    </CityArtContainer>
  );
};

export default CityArt;