import React from 'react';
import Header from '../components/Header';
import MapOverlay from '../components/MapOverlay';
import HomeDescription from '../components/HomeDescription';
import CA from '../components/CA';
import SocialFi from '../components/SocialFi';
import SocialLinks from '../components/SocialLinks';
import Footer from '../components/Footer';
import { NikeCard } from "../components/nikeCard";

function Home() {
  return (
    <div className="App">
      <SocialLinks />
      <Header />
      <MapOverlay />
      <HomeDescription />
      <CA />
      <SocialFi />
      <NikeCard />      
      <Footer />
    </div>
  );
}

export default Home; 