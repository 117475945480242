import React from "react";
import styled from "styled-components";
import Progress from "./Progress";

// Styled Components
const DownloadContainer = styled.div`
  text-align: center;
  margin: 20px 0;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;
const Subtitle = styled.h3`
    margin-top: 60px;
    font-family: 'press start 2p', cursive;
    color:#000000;
`;
const DownloadLink = styled.a`
    align-items: center;
    background: #fff;
    border-radius: .02604rem;
    cursor: pointer;
    display: flex;
    height: 5rem;
    justify-content: center;
    padding: 0 5%;
    border-radius: 10px;

    
    img {
       
        cursor: pointer;

        &:hover {
        transform: scale(1.1);
        transition: transform 0.2s ease-in-out;
        }
    }


`;

// React Component
const DownloadSection = () => {
  return (
    <DownloadContainer>
      {/* <Title>Buidl Progress: 40%</Title> */}
      <Progress progress={40} />
      <Subtitle>Coming In February 2025</Subtitle>
      <LinksContainer>
        
        <DownloadLink href="#">
          <img src="/img/iosbg.svg" alt="Download on iOS" />
        </DownloadLink>
        <DownloadLink href="#">
          <img src="/img/googlePlay.svg" alt="Download on Google Play" />
        </DownloadLink>
   
      </LinksContainer>
    </DownloadContainer>
  );
};

export default DownloadSection;