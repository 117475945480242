import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import Go from "../pages/Go";
import Community from "../pages/Community";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/go",
    element: <Go />,
  },
  {
    path: "/community",
    element: <Community />,
  }
]);

export default router; 