import React, { useState } from 'react';
import styled from "styled-components";
import { motion } from "framer-motion";

// Animation variants
const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.6,
      delay: 1.5
    }
  }
};

const DescriptionContainer = styled.div`
    width: 100vw;
    min-height: 100vh;
    padding: 2rem 0;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-family: 'Press Start 2P', cursive;
    
    @media (max-width: 768px) {
        padding: 4rem 0;
        width: 100%;
        min-height: 100vh;
        box-sizing: border-box;
        overflow-x: hidden;
    }
`;

const ContentWrapper = styled(motion.div)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    gap: 2rem;
    
    @media (max-width: 1024px) {
        width: 95%;
        gap: 1.5rem;
    }
    
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 3rem;
    }
`;

const LeftSection = styled(motion.div)`
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    
    @media (max-width: 768px) {
        width: 100%;
        text-align: center;
    }
`;

const RightSection = styled(motion.div)`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Heading = styled(motion.h1)`
    font-size: 5rem;
    margin-bottom: 2rem;
    color: #333;
    font-weight: 500;
    
    @media (max-width: 1024px) {
        font-size: 4rem;
    }
    
    @media (max-width: 768px) {
        font-size: 3rem;
        margin-bottom: 1.5rem;
    }
    
    @media (max-width: 480px) {
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }
`;

const Description = styled(motion.p)`
    font-size: 1rem;
    line-height: 2;
    color: #666;
    font-weight: 100;
    font-family: monospace !important;
    
    @media (max-width: 1024px) {
        font-size: 1.1rem;
    }
    
    @media (max-width: 768px) {
        font-size: 1rem;
        line-height: 1.8;
    }
    
    @media (max-width: 480px) {
        font-size: 0.9rem;
        line-height: 1.6;
    }
`;

const PreHeading = styled(motion.div)`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    color: #FFDE4D;
    
    @media (max-width: 768px) {
        justify-content: center;
        margin-bottom: 0.8rem;
    }
`;

const Readmore = styled(motion.a)`
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #FFDE4D;
    text-align: right;
    width: 100%;
    margin-top: 2rem;
`;

const Image = styled(motion.img)`
    width: 30%;
    min-height: 600px;
    border-radius: 8px;
    object-fit: cover;
    opacity: ${props => props.isLoaded ? 1 : 0};
    transition: opacity 0.3s ease-in-out;

    @media (max-width: 768px) {
        width: 100%;
        height: 600px;
        object-fit: contain;
        min-height: unset;
    }
`;

const Line = styled.div`
    width: 48px;
    height: 5px;
    background-color: #FFDE4D;
`;

const GetStartedText = styled.span`
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    
    @media (max-width: 1024px) {
        font-size: 1.8rem;
    }
    
    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
    
    @media (max-width: 480px) {
        font-size: 1.2rem;
    }
`;

const TopBlur = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none; /* Allow clicks to pass through the blur */
    z-index: 1; /* Place blur beneath the content */
`;

const HomeDescription = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <DescriptionContainer>
        <TopBlur />
        <ContentWrapper
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeInUp}
        >
          <LeftSection variants={fadeInUp}>
            <PreHeading variants={fadeInUp}>
              <Line />
              <GetStartedText>REAL WORLD PLAY-TO-EARN</GetStartedText>
            </PreHeading>
            <Heading variants={fadeInUp}>Treasure Hunt</Heading>
            <Description variants={fadeInUp}>
              Whether you're in a park, a bustling street, or a quiet museum, you can hide treasures for others to discover.
              It might be a heartfelt letter meant for someone special but left unsent.
              It could be a thoughtful gift waiting for the first lucky finder.
              Every hidden treasure, no matter how small, carries its own unique value.
              When it comes an consensus that one thing is admired by many, it will be a gem for you. 
            </Description>
            <Readmore href="/go" variants={fadeInUp}>[Read More]</Readmore>
          </LeftSection>
          <RightSection variants={fadeInUp}>
            <Image 
              src="/img/playtoearn.gif" 
              alt="treasure hunt"
              variants={fadeInUp}
              onLoad={handleImageLoad}
              isLoaded={imageLoaded}
            />
          </RightSection>
        </ContentWrapper>
    </DescriptionContainer>
  )
}

export default HomeDescription