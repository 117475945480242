import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import GoBanner from '../components/GoBanner';
import GameTutorial from '../components/GameTutorial/GameTutorial';
import Core from '../components/GameTutorial/Core';
import CityArt from '../components/GameTutorial/CityArt';
import NFT from '../components/GameTutorial/NFT';
function Go() {
  return (
    <div className="App">
      <SocialLinks />
      <Header />    
      <GoBanner /> 
      <GameTutorial />
      <Core />
      <CityArt />
      <NFT />
      <Footer />
    </div>
  );
}

export default Go;