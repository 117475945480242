import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { motion, useSpring, useTransform } from 'framer-motion';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook, FaWeixin, FaMapMarkerAlt, FaSearch, FaTrophy, FaTwitter } from 'react-icons/fa';

const TutorialContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  font-family: 'Press Start 2P', cursive;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    height: 80vh;
    transform: translateY(-20%);
  }
`;

const Title = styled.h2`
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
  padding: 2rem;
  color: #1f2937;
  
  @media (max-width: 1024px) {
    font-size: 4rem;
    padding: 1.5rem;
  }
  
  @media (max-width: 768px) {
    font-size: 3rem;
    padding: 1rem;
  }
  
  @media (max-width: 480px) {
    font-size: 2rem;
    padding: 0.8rem;
  }
`;

const CardsContainer = styled(motion.div)`
  display: flex;
  width: 300%;
  height: 70%;
  position: relative;
  will-change: transform;
  
  @media (max-width: 768px) {
    height: 80%;
  }
`;

const StepContainer = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const StepCard = styled(motion.div)`
  background: white;
  border-radius: 0.5rem;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 28rem;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    padding: 1.5rem;
    max-width: 90%;
    height: 90%;
  }
  
  @media (max-width: 480px) {
    padding: 1rem;
    height: 95%;
  }
`;

const StepTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #1f2937;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }
  
  @media (max-width: 480px) {
    font-size: 1rem;
    margin-bottom: 0.6rem;
  }
`;

const StepText = styled.p`
  text-align: center;
  margin-bottom: 1.5rem;
  line-height: 1.5;
  color: #4b5563;
  font-family: monospace !important;

  
  @media (max-width: 768px) {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    line-height: 1.4;
  }
  
  @media (max-width: 480px) {
    font-size: 0.8rem;
    margin-bottom: 0.8rem;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  
  @media (max-width: 768px) {
    gap: 0.8rem;
  }
  
  @media (max-width: 480px) {
    gap: 0.6rem;
  }
`;

const SocialIconsContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  
  svg {
    cursor: pointer;
    transition: transform 0.2s ease;
    
    &:hover {
      transform: scale(1.1);
    }
    
    @media (max-width: 768px) {
      transform: scale(0.9);
      
      &:hover {
        transform: scale(1);
      }
    }
    
    @media (max-width: 480px) {
      transform: scale(0.8);
    }
  }
`;

const ExampleBox = styled.div`
  background-color: #f3f4f6;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  width: 90%;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ExampleText = styled.p`
  font-size: 0.875rem;
  color: #4b5563;
  text-align: center;
`;

const ScrollArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ProgressIndicators = styled.div`
  display: flex;
  position: fixed;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  gap: 0.5rem;
  flex-direction: column;
`;

const ProgressDot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: ${props => props.active ? '#3B82F6' : '#D1D5DB'};
  transition: background-color 0.3s ease;
`;

function GameTutorial() {
  const scrollRef = useRef(null);
  const scrollProgress = useSpring(0);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    
    const handleScroll = () => {
      if (scrollElement) {
        const scrollPercentage = scrollElement.scrollTop / 
          (scrollElement.scrollHeight - scrollElement.clientHeight);
        scrollProgress.set(scrollPercentage);
      }
    };

    scrollElement?.addEventListener('scroll', handleScroll);
    return () => scrollElement?.removeEventListener('scroll', handleScroll);
  }, [scrollProgress]);

  const x = useTransform(
    scrollProgress,
    [0, 1],
    ['0%', '-66.666%']
  );

  return (
    <TutorialContainer>
      <Title>How to Play</Title>
      
      <CardsContainer style={{ x }}>
        {/* Step 1: Login */}
        <StepContainer>
          <StepCard>
            <StepTitle>Step 1: Login</StepTitle>
            <StepText>Sign in with your preferred social account:</StepText>
            <SocialIconsContainer>
              <FcGoogle size={32} />
              <FaTwitter size={32} />
              <FaFacebook size={32} style={{ color: '#1877f2' }} />
              <FaWeixin size={32} style={{ color: '#07C160' }} />
            </SocialIconsContainer>
          </StepCard>
        </StepContainer>

        {/* Step 2: Location Permission */}
        <StepContainer>
          <StepCard>
            <StepTitle>Step 2: Enable Location</StepTitle>
            <IconContainer>
              <FaMapMarkerAlt size={48} style={{ color: '#EF4444' }} />
              <StepText>
                Enable location services to discover items nearby on the map.
                This helps you find hidden treasures in your vicinity!
              </StepText>
            </IconContainer>
          </StepCard>
        </StepContainer>

        {/* Step 3: Search and Find */}
        <StepContainer>
          <StepCard>
            <StepTitle>Step 3: Search and Collect</StepTitle>
            <IconContainer>
              <SocialIconsContainer>
                <FaSearch size={40} style={{ color: '#3B82F6' }} />
                <FaTrophy size={40} style={{ color: '#F59E0B' }} />
              </SocialIconsContainer>
              <StepText>
                Find items in the real world and enter their pair number in the app
                to claim your reward and earn XP!
              </StepText>
              <ExampleBox>
                <ExampleText>
                  Example: Enter item code "ABC123" to verify your find
                </ExampleText>
              </ExampleBox>
            </IconContainer>
          </StepCard>
        </StepContainer>
      </CardsContainer>

      <ScrollArea ref={scrollRef}>
        <div style={{ height: '300%' }} />
      </ScrollArea>

      <ProgressIndicators>
        {[0, 1, 2].map((step) => (
          <ProgressDot
            key={step}
            active={scrollProgress.get() * 3 > step}
          />
        ))}
      </ProgressIndicators>
    </TutorialContainer>
  );
}

export default GameTutorial; 