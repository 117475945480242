// Header.jsx
import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu, X } from 'lucide-react';
import WalletButton from './WalletButton';
import logo from '../assets/images/logo.png';

const HeaderContainer = styled.header`
  background: transparent;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  font-family: 'Press Start 2P', cursive !important;
  transition: all 0.3s ease;
  
  
  
  @media (max-width: 768px) {
    background: ${props => props.isOpen ? 'white' : 'rgba(255, 255, 255, 0.9)'};
  }
`;

const Nav = styled.nav`
  margin: 1% 2% 0% 2%;
  padding: 0 2rem;
   margin: 0;
    padding: 1% 2%;
    width: 100%;
    box-sizing: border-box;
  
  @media (max-width: 1024px) {
    padding: 0 1.5rem;
    margin: 0.5% 1%;
  }
  
  @media (max-width: 768px) {
    margin: 0;
    padding: 0 1rem;
  }
`;

const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
 
  margin: 0 auto;
  width: 100%;
`;

const Logo = styled.a`
  font-size: 2.5rem;
  font-weight: bold;
  padding-top:1rem;
  color: #2563eb;
  cursor: pointer;
  transition: color 0.3s ease;
  text-decoration: none;
  img {
    width: 200px;
    height: 200px;
  }
  
  @media (max-width: 768px) {
    font-size: 1.8rem;

    img{
    width:100px;
    height:100px;
    }
  }
  
  @media (max-width: 480px) {
    font-size: 1.5rem;
      img{
    width:80px;
    height:80px;
    }
  }
`;

const DesktopMenu = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
`;

const MenuItemsContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-right: 2rem;

  @media (max-width: 1024px) {
    margin-right: 1.5rem;
    gap: 1.5rem;
  }
`;

const MenuItem = styled.a`
  color: #374151;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 200;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  transition: all 0.3s ease;

  &:hover {
    color: #2563eb;
    background: rgba(37, 99, 235, 0.1);
    transform: translateY(-1px);
  }
  
  @media (max-width: 1024px) {
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
  }
`;

const MobileMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.375rem;
  color: #374151;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #2563eb;
    background: rgba(37, 99, 235, 0.1);
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  display: ${props => props.isOpen ? 'flex' : 'none'};
  flex-direction: column;
  padding: 0.5rem 0;
  background: white;
  border-top: 1px solid #e5e7eb;
  position: absolute;
  left: 0;
  right: 0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    display: none;
  }
`;

const MobileMenuItem = styled.a`
  display: block;
  color: #374151;
  text-decoration: none;
  padding: 0.75rem 1rem;
  font-size: 0.7rem;
  font-weight: 500;
  transition: all 0.3s ease;

  &:hover {
    color: #2563eb;
    background: rgba(37, 99, 235, 0.1);
  }
`;

const MobileWalletContainer = styled.div`
  padding: 0.75rem 1rem;
  border-top: 1px solid #e5e7eb;
  margin-top: 0.5rem;
`;

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const menuItems = [
    { label: 'How to Play', href: '/go' },
    { label: 'Whitepaper', href: 'https://cath-doc.gitbook.io/whitepaper.cath.live/',target: '_blank',
      rel: 'noopener noreferrer'  // Security best practice for external links
     },
    { label: 'Community', href: '/community' }
  ];

  return (
    <HeaderContainer isOpen={isMobileMenuOpen}>
      <Nav>
        <NavContent>
          <Logo href="/"><img src={logo} alt="CATH" /></Logo>

          <DesktopMenu>
            <MenuItemsContainer>
              {menuItems.map((item) => (
                <MenuItem key={item.label} href={item.href} target={item.target}
                rel={item.rel}>
                  {item.label}
                </MenuItem>
              ))}
            </MenuItemsContainer>
            <WalletButton />
          </DesktopMenu>

          <MobileMenuButton onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
            {isMobileMenuOpen ? (
              <X size={24} />
            ) : (
              <Menu size={24} />
            )}
          </MobileMenuButton>
        </NavContent>

        <MobileMenu isOpen={isMobileMenuOpen}>
          {menuItems.map((item) => (
            <MobileMenuItem key={item.label} href={item.href}>
              {item.label}
            </MobileMenuItem>
          ))}
          <MobileWalletContainer>
            <WalletButton />
          </MobileWalletContainer>
        </MobileMenu>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;