import React, {useRef} from 'react';
import styled from "styled-components";
import YouTube from 'react-youtube';
import { motion } from "framer-motion";


// Animation variants
const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.6,
      delay: 1.5
    }
  }
};

const DescriptionContainer = styled.div`
    width: 100vw;
    min-height: 100vh;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-family: 'Press Start 2P', cursive;
    padding: 2rem 0;
    
    @media (max-width: 768px) {
        padding: 4rem 1rem;
        height: auto;
        width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    overflow-x: hidden;
    }
`;

// Convert styled components to motion components
const ContentWrapper = styled(motion.div)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    gap: 2rem;
    
    @media (max-width: 1024px) {
        width: 95%;
        gap: 1.5rem;
    }
    
    @media (max-width: 768px) {
        flex-direction: column-reverse;
        gap: 3rem;
    }
`;

const LeftSection = styled(motion.div)`
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    
    @media (max-width: 768px) {
        width: 100%;
        text-align: center;
        align-items: center;
    }
`;

const RightSection = styled(motion.div)`
    flex: 1;
    
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const Heading = styled(motion.h1)`
    font-size: 5rem;
    margin-bottom: 2rem;
    color: #333;
    font-weight: 500;
    
    @media (max-width: 1024px) {
        font-size: 4rem;
    }
    
    @media (max-width: 768px) {
        font-size: 3rem;
        margin-bottom: 1.5rem;
        text-align: center;
    }
    
    @media (max-width: 480px) {
        font-size: 2rem;
        margin-bottom: 1rem;
    }
`;

const Description = styled(motion.p)`
    font-size: 1rem;
    line-height: 2;
    color: #666;
    font-weight: 100;
    font-family: monospace !important;
    
    @media (max-width: 1024px) {
        font-size: 1.1rem;
    }
    
    @media (max-width: 768px) {
        font-size: 1rem;
        line-height: 1.8;
        text-align: center;
        br {
            display: none;
        }
    }
    
    @media (max-width: 480px) {
        font-size: 0.8rem;
        line-height: 1.6;
    }
`;

const VideoWrapper = styled(motion.div)`
    width: 100%;
    position: relative;
    height: 50vh;
    
    @media (max-width: 768px) {
        height: 40vh;
    }
    
    @media (max-width: 480px) {
        height: 30vh;
    }
`;

const CopyrightNotice = styled(motion.div)`
    font-size: 0.8rem;
    color: #666;
    margin-top: 1rem;
    text-align: center;
    font-style: italic;
    
    @media (max-width: 768px) {
        font-size: 0.7rem;
        margin-top: 0.8rem;
    }
    
    @media (max-width: 480px) {
        font-size: 0.6rem;
        margin-top: 0.5rem;
    }
`;
const Video = styled(motion.video)`
    width: 100%;
    height: 50vh;
    max-width: 500px;
    min-height: 50vh;
    border-radius: 8px;
    object-fit: cover;
    
    @media (max-width: 768px) {
        height: 40vh;
        min-height: 40vh;
        max-width: 100%;
    }
    
    @media (max-width: 480px) {
        height: 30vh;
        min-height: 30vh;
    }
`;

const VideoContainer = styled.div`
    width: 100%;
    height: 50vh;
    max-width: 500px;
    min-height: 50vh;
    margin: 0 auto;

    @media (max-width: 768px) {
        height: 40vh;
        min-height: 40vh;
        max-width: 100%;
    }
    
    @media (max-width: 480px) {
        height: 30vh;
        min-height: 30vh;
    }

    .video {
        width: 100%;
        height: 100%;

        iframe {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
`;

const PreHeading = styled(motion.div)`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    color: #FFDE4D;
    
    @media (max-width: 768px) {
        justify-content: center;
        margin-bottom: 0.8rem;
    }
`;

const Line = styled.div`
    width: 48px;
    height: 5px;
    background-color: #FFDE4D;
    
    @media (max-width: 480px) {
        width: 32px;
        height: 3px;
    }
`;

const GetStartedText = styled.span`
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    
    @media (max-width: 1024px) {
        font-size: 1.8rem;
    }
    
    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
    
    @media (max-width: 480px) {
        font-size: 1.2rem;
        letter-spacing: 1px;
    }
`;

const Readmore = styled(motion.a)`
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #FFDE4D;
    text-align: right;
    width: 100%;
    margin-top: 2rem;
    
    @media (max-width: 1024px) {
        font-size: 1rem;
    }
    
    @media (max-width: 768px) {
        font-size: 1.2rem;
        margin-top: 1.5rem;
        text-align: center;
    }
`;

const TopBlur = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none; /* Allow clicks to pass through the blur */
    z-index: 1; /* Place blur beneath the content */
`;

const CA = () => {
    const videoRef = useRef(null);

  
  return (
    <DescriptionContainer>
        <TopBlur />
        <ContentWrapper
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeInUp}
        >
          <RightSection variants={fadeInUp}>
            <VideoWrapper variants={fadeInUp}>
              <VideoContainer>
              <Video 
              ref={videoRef}
              loop 
              muted 
              autoPlay
              playsInline
              variants={fadeInUp}
            >
              <source src="/video/ca.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </Video>
              </VideoContainer>
              <CopyrightNotice variants={fadeInUp}>
                © 2024 zeoh.3d. All rights reserved. Big shout out to all the amazing artists who made this possible.
              </CopyrightNotice>
            </VideoWrapper>
          </RightSection>
          <LeftSection variants={fadeInUp}>
            <PreHeading variants={fadeInUp}>
              <Line />
              <GetStartedText>THE REAL CA</GetStartedText>
            </PreHeading>
            <Heading variants={fadeInUp}>CITY/COUNTRY ARTWORKS</Heading>
            <Description variants={fadeInUp}>
              Join the city decoration revolution community.<br />Earn huge rewards by decorating your city with art or stopping by to see what others have done.
            </Description>
            <Readmore href="/go#ca" variants={fadeInUp}>[Read More]</Readmore>
          </LeftSection>
        </ContentWrapper>
    </DescriptionContainer>
  )
}

export default CA