import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import CommunityBanner from '../components/CommunityBanner';
import OpeningVote from '../components/OpeningVote';
import ClosedVote from '../components/ClosedVote';

function Community() {
  return (
    <div>
      <SocialLinks />
      <Header />
      <CommunityBanner />
      <OpeningVote />
      <ClosedVote />
      <Footer />
    </div>
  );
}

export default Community;