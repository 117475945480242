import React, { createContext, useContext, useState, useEffect } from 'react';

const WalletContext = createContext();

export const useWallet = () => {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error('useWallet must be used within a WalletProvider');
  }
  return context;
};

export const WalletProvider = ({ children }) => {
  const [publicKey, setPublicKey] = useState(null);
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    // Check if wallet is connected on component mount
    const checkWalletConnection = async () => {
      try {
        const provider = window?.solana;
        if (provider?.isPhantom) {
          const response = await provider.connect({ onlyIfTrusted: true });
          setPublicKey(response.publicKey.toString());
          setConnected(true);
        }
      } catch (error) {
        // Handle connection error
        console.error('Wallet connection error:', error);
      }
    };

    checkWalletConnection();
  }, []);

  const connectWallet = async () => {
    try {
      const provider = window?.solana;
      
      if (!provider) {
        window.open('https://phantom.app/', '_blank');
        return;
      }

      if (provider?.isPhantom) {
        const response = await provider.connect();
        setPublicKey(response.publicKey.toString());
        setConnected(true);
        return true;
      }
    } catch (error) {
      console.error('Wallet connection error:', error);
      return false;
    }
  };

  const disconnectWallet = async () => {
    try {
      const provider = window?.solana;
      if (provider?.isPhantom) {
        await provider.disconnect();
        setPublicKey(null);
        setConnected(false);
      }
    } catch (error) {
      console.error('Wallet disconnect error:', error);
    }
  };

  const value = {
    publicKey,
    connected,
    connectWallet,
    disconnectWallet
  };

  return (
    <WalletContext.Provider value={value}>
      {children}
    </WalletContext.Provider>
  );
}; 