import React from 'react';
import styled, { keyframes } from 'styled-components';
import physical from "../../assets/images/physical.png"
import digital from "../../assets/images/digital.png"

const NFTContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: #ffffff;
    position: relative;
    overflow: hidden;
    font-family: 'Press Start 2P', cursive;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    box-sizing: border-box;
    
    @media (max-width: 768px) {
        padding: 1rem;
    }
`;

const Content = styled.div`
    display: flex;
    height: auto;
    width: 90%;
    max-width: 1400px;
    margin: 2rem auto;
    padding: 1rem;
    
    @media (max-width: 1024px) {
        width: 95%;
    }
    
    @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        margin: 1rem auto;
    }
`;

const Section = styled.div`
    flex: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    
    @media (max-width: 1024px) {
        padding: 1.5rem;
        gap: 1.2rem;
    }
    
    @media (max-width: 768px) {
        padding: 1rem;
        gap: 1rem;
    }
    
    @media (max-width: 480px) {
        padding: 0.8rem;
        gap: 0.8rem;
    }
`;

const LeftSection = styled(Section)``;
const RightSection = styled(Section)``;

const Heading = styled.h1`
    font-size: 5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
    
    @media (max-width: 1200px) {
        font-size: 4rem;
    }
    
    @media (max-width: 768px) {
        font-size: 3rem;
        margin-bottom: 1.5rem;
    }
    
    @media (max-width: 480px) {
        font-size: 2rem;
        margin-bottom: 1rem;
    }
`;

const SubHeading = styled.h2`
    font-size: 1.5rem;
    font-weight: bold;
    width: 80%;
    text-align: center;
    margin: 0 auto 2rem;
    
    @media (max-width: 1200px) {
        font-size: 1rem;
        width: 90%;
    }
    
    @media (max-width: 768px) {
        font-size: 1.1rem;
        width: 95%;
        margin-bottom: 1.5rem;
    }
    
    @media (max-width: 480px) {
        font-size: 0.9rem;
        width: 100%;
        margin-bottom: 1rem;
    }
`;

const blink = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0.3; }
    100% { opacity: 1; }
`;

const Highlight = styled.p`
    font-size: 1.2rem;
    font-weight: bold;
    animation: ${blink} 2s ease-in-out infinite;
    color: #ccc498;
    text-align: center;
    margin: 1rem 0;
    
    @media (max-width: 1200px) {
        font-size: 1.1rem;
    }
    
    @media (max-width: 768px) {
        font-size: 1rem;
    }
    
    @media (max-width: 480px) {
        font-size: 0.9rem;
    }
`;

const ExampleText = styled.p`
    font-size: 1rem;
    color: #4b5563;
    line-height: 1.6;
    font-family: monospace !important;

    
    @media (max-width: 768px) {
        font-size: 0.9rem;
        line-height: 1.5;
    }
    
    @media (max-width: 480px) {
        font-size: 0.8rem;
        line-height: 1.4;
    }
`;

const Title = styled.h2`
    font-size: 1.5rem;
    font-weight: bold;
    
    @media (max-width: 1200px) {
        font-size: 1rem;
    }
    
    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
    
    @media (max-width: 480px) {
        font-size: 1.1rem;
    }
`;

const NFTImage = styled.img`
    width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: contain;
    transition: transform 0.3s ease;
    transform: perspective(800px) rotateY(-15deg) skew(2deg);
    
    @media (hover: hover) {
        &:hover {
            transform: perspective(800px) rotateY(15deg) skew(-2deg) scale(1.05);
        }
    }
    
    @media (max-width: 1200px) {
        max-height: 350px;
        transform: perspective(800px) rotateY(-10deg) skew(1.5deg);
    }
    
    @media (max-width: 768px) {
        max-height: 300px;
        transform: perspective(800px) rotateY(-8deg) skew(1deg);
    }
    
    @media (max-width: 480px) {
        max-height: 250px;
        transform: perspective(800px) rotateY(-5deg) skew(0.5deg);
    }
    
    @media (hover: none) {
        transform: none;
        &:active {
            transform: scale(1.02);
        }
    }
`;

const NFT = () => {
  return (
    <NFTContainer>
        <Heading>Make NFT Great Again</Heading>
        <SubHeading>If Fully Digital Assets NFT is not working out and losing its strength in the market, WHAT ABOUT Real World Assets NFT? </SubHeading>
        <Highlight>For highly rated gems in the game, minted NFTs will be available for sale in the marketplace.</Highlight>
      <Content>
        <LeftSection>
          <Title>Physical Assets NFT</Title>
          <ExampleText>Highly rated items can be minted as NFTs and sold in the marketplace.</ExampleText>
          <NFTImage src={physical} alt="Physical Asset NFT" />
        </LeftSection>
        <RightSection>
        <Title>Digital Assets Marketplace</Title>
        <ExampleText>All digital assets found in the game can be traded in the marketplace. For example, vouchers, membership cards and etc. </ExampleText>
        <NFTImage src={digital} alt="Digital Asset" />
        </RightSection>
      </Content>
    </NFTContainer>
  )
}

export default NFT