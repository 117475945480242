import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Container = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  
  @media (max-width: 768px) {
    height: 80vh !important; // Adjust for mobile header
  }
`;

const SVGWrapper = styled.div`
  width: 100%;
  height: 100%;
  
  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    
    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
      object-position: center;
    }
  }
`;

const TextBox = styled.div`
  position: absolute;
  left: 25%;
  top: 35%;
  z-index: 10;
  color: #000000;
  width: 40%;
  padding: 20px;
  border-radius: 8px;
  font-family: 'Press Start 2P', cursive;
  
  h2 {
    font-size: 5rem;
    margin-bottom: 2rem;

    @media (max-width: 1200px) {
      font-size: 4rem;
    }
    
    @media (max-width: 768px) {
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
    }
    
    @media (max-width: 480px) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }
  
  p {
    font-size: 1rem;
    line-height: 1.6;
    color: #ccc498;

    @media (max-width: 1200px) {
      font-size: 1.1rem;
    }
    
    @media (max-width: 768px) {
      font-size: 0.9rem;
      line-height: 1.5;
    }
    
    @media (max-width: 480px) {
      font-size: 0.8rem;
      line-height: 1.4;
    }
  }

  @media (max-width: 1200px) {
    width: 50%;
    left: 20%;
  }
  
  @media (max-width: 768px) {
    width: 80%;
    left: 10%;
    top: 7%;
    padding: 15px;
  }
  
  @media (max-width: 480px) {
    width: 90%;
    left: 5%;
    padding: 10px;
  }
`;

const BottomBlur = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  pointer-events: none;
  z-index: 1;

  @media (max-width: 1024px) {
    height: 20px;
    bottom: 25%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0.9) 60%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  
  @media (max-width: 768px) {
    height: 18vh;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.7) 25%,
      rgba(255, 255, 255, 0.95) 50%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  
  @media (max-width: 480px) {
    height: 15vh;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 20%,
      rgba(255, 255, 255, 1) 40%,
      rgba(255, 255, 255, 1) 100%
    );
  }
`;

function CommunityBanner() {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    
    const setupAnimations = () => {
      // Initial positions and states
      gsap.set('#p1', { opacity: 1 });
      gsap.set('#p2', { xPercent: 100 }); // Start off-screen right
      gsap.set('#p3', { xPercent: 100 }); // Start off-screen right
      gsap.set('#p4', { xPercent: 100 }); // Start off-screen right
      
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          start: 'top top',
          end: '+=300%', // Longer scroll duration
          scrub: 1,
          pin: true,
          markers: false // Remove in production
        }
      });

      // Animation sequence
      tl.to('#p1', {
        opacity: 0,
        duration: 1
      })
      .to('#p2', {
        xPercent: -100, // Move to p1's position
        duration: 1
      }, '<') // Start at same time as p1 fade
      .to('#p3', {
        xPercent: 0, // Move to p2's position
        duration: 1
      }, '>') // Start after p2
      .to('#p4', {
        xPercent: 0, // Move to p3's position
        duration: 1
      }, '>'); // Start after p3
    };

    setupAnimations();

    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <Container ref={containerRef}>
      <SVGWrapper dangerouslySetInnerHTML={{
        __html: `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1160 772" width="2885" height="1920">
	<title>3238340_44100</title>
	<style>
		.s0 { fill: #ffffff } 
		.s1 { fill: #6491ad } 
		.s2 { fill: #6d9dbf } 
		.s3 { opacity: .6;fill: #272525 } 
		.s4 { fill: #6e6659 } 
		.s5 { fill: #4a4e31 } 
		.s6 { fill: #453c36 } 
		.s7 { fill: #aa6a64 } 
		.s8 { fill: #333333 } 
		.s9 { fill: #592626 } 
		.s10 { fill: #2e4ab2 } 
		.s11 { fill: #af716d } 
		.s12 { opacity: .3;fill: #272525 } 
		.s13 { fill: #e8dbce } 
		.s14 { fill: #afd9c4 } 
		.s15 { fill: #acb18f } 
		.s16 { fill: #efe7df } 
		.s17 { fill: #ebefeb } 
		.s18 { fill: #e0c74e } 
		.s19 { fill: #e8e8e8 } 
		.s20 { fill: #736357 } 
		.s21 { fill: #6087a2 } 
		.s22 { fill: #7398ad } 
		.s23 { fill: #4d4d4d } 
		.s24 { fill: #564c44 } 
		.s25 { fill: #a07e6c } 
		.s26 { fill: #5f544b } 
		.s27 { fill: #a78e41 } 
		.s28 { fill: #441a19 } 
	</style>
	<g id="background">
		<path id="&lt;Path&gt;" class="s0" d="m1160 772h-1160v-772h1160z"/>
	</g>
	<g id="Object">
		<g id="table">
			<g id="&lt;Group&gt;">
				<path id="&lt;Path&gt;" class="s1" d="m89.8 328.7c1.2 14 1.4 27.8 1.4 42 0 20.7 0 41.6 0 62.4h198.8c0-25 0-49.5 0-74.1 0-7.8 1.4-19.4 1.4-29.8z"/>
				<path id="&lt;Path&gt;" class="s2" d="m290.6 309.4c-6.5-1.9-19-1-28.1-1q-26.9 0-53.8 0c-21.4 0-42.8-0.4-64.3-0.4-10.8-0.1-21.7-0.1-32.5 0.1-5.1 0-10.6-0.4-15.7 0.3-4 0.5-9.1-0.5-12.9 0.7q0.3 9.6 1.4 19.1c0.4 4-0.2 10.2 4.1 12 3.6 1.4 9.9 0.3 13.8 0.3q16.5 0 32.9 0 43.3 0 86.6 0 32.5 0 65 0 3.4 0 6.8 0c1.5-8.3 1.9-16.9 2.7-25.3 0.1-1.4 0.3-2.9-0.4-4.1-1.1-2.1-3.7-1.2-5.6-1.7z"/>
				<path id="&lt;Path&gt;" class="s3" d="m217.2 316.9c-1.5 0.4-3.8 0.6-5.5 0.6-6.8 0.1-13.6-0.2-20.5-0.2q-9.3 0-18.6 0c-5.1 0-9.7 0.9-10.1-2-0.3-2.5 4.3-1.8 8.5-1.7 5.1 0 10.2-0.2 15.3-0.2q0 0 0 0c7.2 0 14.4-0.5 21.7-0.2 2.7 0.1 7.9 0.3 9.9 1.4 1.8 1.1 1 1.9-0.7 2.3z"/>
				<g id="&lt;Group&gt;" style="opacity: .9">
					<path id="&lt;Compound Path&gt;" class="s0" d="m159.7 367.6c0-0.1 0.3-0.3 0.5-0.4 2.3-0.5 3.2 0.4 3.5 0.6 0.4 0.6 0.4 0.8-0.3 6.2-1 8.4-1.5 11.4-1.9 15.8-0.3 2.3-0.4 4.4-0.4 4.4-0.8 1.2-2.8 0.8-3.7 0.4-0.6-0.3-0.7-0.4-1.9-4.2-0.6-1.6-4.7-14.9-5.5-17.7-0.5-1.3-1.1-3-0.9-3.6 0.2-0.8 0.9-1.1 1.4-0.9 1.5 0.2 2 0.4 2.5 1.5 1 1.4 4.6 13.4 4.6 13.3 0.1 0 2-15.1 2.1-15.4z"/>
					<path id="&lt;Compound Path&gt;" class="s0" d="m170.3 368c0-0.2 0.3-0.5 0.5-0.6 0.4-0.5 0.4-0.5 1.6-0.3 1.5 0 2.7 0.6 4.1 1.9 4.7 4.2 5.6 10.4 5.5 13.6-0.2 7.3-3.5 10.4-6 11.7-3 1.2-5.5 0.4-6.9-0.4-4.8-2.1-4.4-13.2-3.8-15.5 1.5-6.9 3.3-8.3 4.4-9.4 0.3-0.4 0.6-0.8 0.6-1zm4.8 5.9c-0.4-1-1-1.9-1.3-2.2-0.3-0.5-0.5-0.7-0.7-0.7-4.9 3.9-4.2 15.2-2.3 18.7 0.4 0.8 1.1 1.7 1.2 1.7 0.8 0.2 2.6-1.4 3.5-2.8 2.4-4.3 2.2-9.7-0.4-14.7z"/>
					<path id="&lt;Compound Path&gt;" class="s0" d="m190.8 367.4c2.1-0.3 4.2-0.6 4.5-0.6 1.2-0.1 3.5 0 4 0.3 1.2 0.8 1.7 1.8 0.9 2.6-0.5 0.5-0.3 0.5-2.9 0.5-1.1 0-2.5 0-3 0.1-0.9 0.2-0.9 0.2-0.9 2.1 0.3 5.8 0.9 13.7 1.6 17 0.4 2.7 0.6 4.1 0.4 4.6-0.1 0.5-1.2 0.9-2.2 0.9-0.9 0-2-0.4-2.4-1.2-0.5-0.5-0.6-1.8-1.1-5.7-0.7-5.9-0.9-11.8-0.9-16-0.2-1-0.2-1.1-1.6-0.9-2.6 0.3-3.8 0.2-4.6-0.6-0.5-0.5-0.7-1.4 0-1.7 0.6-0.5 3.7-0.9 8.2-1.4z"/>
					<path id="&lt;Compound Path&gt;" class="s0" d="m201.7 369c0.9-0.5 4.3-1.2 7.8-1.8 3-0.3 3.7-0.3 4.8 0.4 1.2 1 0.4 2.2 0.1 2.2-4.1 0.5-9 1.2-9 1.2-0.1 0-0.1 4.9-0.3 6.1 0 1.8 0 2 0.2 2 0.1 0.2 3.3-0.7 3.8-0.7 1.8 0 2.9 1.2 2.5 2.2-0.3 0.6-3.1 1.5-5.9 2.1-0.8 0.1-0.8 0.1-0.8 2.3 0.2 2.5 0.4 3.7 0.8 4.7 0.4 1.2 0.3 1.2 2.7-0.7 1.1-0.9 1.9-1.2 3.2-0.7 1.3 0.4 1.7 1.3 1.2 2.5-0.5 0.8-2.1 2.3-3.1 2.8-8.7 3.9-9.5-4.7-9.6-6.8-0.3-4.9 0.9-15.5 1-16.1 0.2-0.1 0.2-0.4 0-0.6-0.1-0.2 0.2-0.9 0.6-1.1z"/>
				</g>
			</g>
			<path id="&lt;Path&gt;" class="s4" d="m89 469.4c0 20.5-0.1 41 0.1 61.5 0.1 20.4 0.1 40.8 0.1 61.3 0 13.2 0 26.7 0 40.1q0.2 0 0.4 0c4.9-0.1 9.8-0.2 14.7-0.4q0.8 0 1.6 0c0-2.1 0-4.1 0-5.9-0.1-25.4-0.1-50.9-0.1-76.3 0-25.8 0.1-51.4 0-77.1-0.1-8.8 0-17.6-0.1-26.4q0-2.1 0-3.9c4.1 0 8.3-0.1 11.6-0.1 22.3 0 44.6-0.1 67-0.1 22.6 0 44.9 0.1 67.5 0 6.8 0 13.6 0 20.4 0-0.2 8.9 0 20.3 0 27.3 0 20.5 0 41 0.1 61.5 0.1 20.4 0.1 40.8 0.1 61.3 0 14.5 0.1 29.4 0.1 44.1q9.3-0.1 18.6-0.5c-0.1-3.5-0.1-6.9-0.1-9.8-0.1-25.4-0.1-50.9-0.1-76.3 0-25.8 0.1-51.4 0-77.1-0.1-8.8 0-17.6 0-26.4-0.1-4.3-0.1-7.5-0.1-10 0.2-3 0.3-6.1-0.6-8.9-4.4 0.6-9.2 0.7-14 0.7-0.8-0.4-1.5-0.6-2.2 0-6.5-0.1-13.2-0.5-19.4-0.5-18 0-36 0-53.9 0.1-17.9 0.1-35.8 0.1-53.7 0.1-18.2 0-37 0-55.1 0q-0.4-0.2-0.8 0-0.4 0-0.8 0c0.1 0 0.2 0.1 0.2 0.4-0.3 0.3-0.5 0.8-0.7 1.5-1.3 3.8-0.8 28-0.8 39.8z"/>
		</g>
		<g id="p1">
			<path id="&lt;Path&gt;" class="s5" d="m342.4 362.3c-7.4 2.9-15.4 6.6-18.9 13.3-2.2 4.1-2.4 8.8-2.5 13.2q-1.1 50.2-2.3 100.4c-0.3 13.5-1.9 26.3 2.4 40.9 8.3-3.7 19.5-5.5 21.6-13.7 7.3-28.2 15.3-51.4 39.2-71.5 7.7-6.5 13.8-28.5 17.1-37.5 2-5.3 1.4-10.8 0.6-16.2q-1.7-11.1-4.1-22.2c-6.1-27.7-31.1-15.3-53.1-6.7z"/>
			<path id="&lt;Path&gt;" class="s5" d="m337.7 495.4c-5.5-1-16.5 12.3-18 17.5-0.6 1.7-0.6 3.6-0.6 5.3-0.3 36.2-0.3 64.8-0.6 101 3 0 5.4 1.6 7.9-0.2 4.4-27.9 14.2-63.6 18.6-91.5 1.4-9 6.6-29.7-7.3-32.1z"/>
			<path id="&lt;Path&gt;" class="s5" d="m373 573.9c6.1 14.4 11.7 24.9 13.1 39.2 7.2-0.2 6.6 1.7 8.6-2.7 1.9-4.5 1.1-9.5-0.1-14.4-3.6-15.5-4.3-32.7-9.3-48.2-7.6-23.3-10.7-46.6-9.4-69.4 0.7-11.7 2.6-23.3 5.6-34.7 2.6-9.3 11.9-20-1.2-28.9-21.9-14.8-44.3-4.9-50 9-7.9 19 3.9 45.7 10.3 66.2 8.8 28 20.6 56 32.4 83.9z"/>
			<path id="&lt;Path&gt;" class="s6" d="m275 304.1c-0.3-0.5-0.4-1-0.7-1.4-0.7-0.9-1.9-1.3-3-1.4-1.1-0.2-2.2-0.1-3.3-0.3-3.4-0.6-6.2-4-9.7-3.5-1 0.2-2 0.7-2.9 1.4-1.1 0.8-2.2 1.9-2.5 3.3-0.4 2.1 1.1 4.2 2.6 5.9 0.8 0.9 1.6 1.9 2.7 2.4 0.9 0.5 1.9 0.7 2.8 0.9 2.5 0.6 4.9 1.5 6.8 3.1 1.6 1.4 2.9 3.3 4.9 3.8 3.6 0.8 7-3.1 7.4-6.4 0.4-3.3-3.9-5-5.1-7.8z"/>
			<path id="&lt;Path&gt;" class="s7" d="m247.1 284.5c-3 1.9-2 5.2 0.1 8.2q37.2-8 74.7-13.4c7.8-1.1 16.3-2.7 21.4-9.6 2.1-3 3.4-6.6 4.3-10.3 1.7-6.6 1.9-14.8-2.7-19.1-6.5-6.1-14.1 0-19.5 4.8-6.4 5.8-10.4 8.1-18.6 11.2-19 7.2-42 17.2-59.7 28.2z"/>
			<path id="&lt;Path&gt;" class="s7" d="m402.9 293.8c-2.1-14.2-5.3-28.7-13.4-40.6-8.2-11.8-22.5-20.4-36.7-18-3.2 0.5-6.4 1.7-8.8 3.8-1.9 1.7-3.2 3.9-4.4 6.2-4 7.9-6.2 16.6-8.2 25.2-8.5 37-14.9 74.5-19 112.2-0.2 2.4-0.4 5.2 1.1 7.1 1.6 1.9 4.5 2.3 7.1 2.4q18 1.3 36.1 2.5c11.3 0.8 22.8 1.6 33.9-0.7 14.5-3 13.7-8 14.8-21.7 2.1-26.1 1.3-52.5-2.5-78.4z"/>
			<path id="&lt;Path&gt;" class="s6" d="m324.3 164.6c2.3-6.8 5.9-15.2 13.3-17 8-1.9 19.3 3.4 25.1 8.4 7.1 6.3 11.6 15.2 14.1 24.3 2.8 10 3.2 21.1-1.3 30.4-4.4 9.4-14.7 16.4-24.9 14.9-6.9-1.1-12.7-5.6-17.2-10.9-11.5-13.5-14.7-33.4-9.1-50.1z"/>
			<path id="&lt;Path&gt;" class="s6" d="m244.8 296.2c-0.7 0.4-1.4 0.6-2.2 0.8q-3 0.9-5.9 1.7c-0.9 0.3-1.8 0.5-2.7 0.5-1 0-1.9-0.4-2.5-1.1-0.5-0.8-0.5-1.7-0.2-2.5 0.2-0.8 0.8-1.5 1.3-2.2 1.4-1.4 3-2.5 4.9-3.2 1.4-0.6 3-0.9 4.2-1.8 0.5-0.5 1-1 1.6-1.4 0.9-0.5 2.1-0.6 3-0.1 3 1.7 0.8 7.8-1.5 9.3z"/>
			<path id="&lt;Path&gt;" class="s8" d="m329.7 144.7c2.5-1.3 2.7-5 4.9-6.7q0.2-0.3 0.3-0.7c0.2-0.8 0-1.7 0.2-2.5 0.5-2.8 4.3-3.5 7-2.8-0.7-3.5 4.5-6.5 7.2-4 0.9-2.7 4-4.5 6.8-4 2.8 0.5 5 3.3 4.9 6.1 1-1.3 2.2-2.3 3.6-3.1 2.5 1.7 4.2 4.6 4.4 7.7 2.9-2.4 8-0.2 8.3 3.5 1.2-1.5 3.8-1.1 5 0.4 1.3 1.5 1.5 3.5 1.7 5.5 1.5-1.9 4.7-1.4 6.2 0.4 1.4 1.9 1.4 4.5 0.8 6.8 3.3 0.8 6.2 2.9 7.9 5.8q-1.1 1.4-2.1 2.8c4.9 0.6 8.8 5.3 8.5 10.2 1.1 1.3 1.5 3.2 1 4.8-0.8 2.2-3.4 3.4-5.7 3 2 1 3.3 3.3 2.9 5.5-0.3 1.6-1.5 2.9-3 3.6 1.6 2.5 2.5 5.2 1.3 7.9-1.1 2.5-3.9 3.8-6.8 3.9-0.5 0.4-1.1 0.6-1.7 0.7-1 0.1-2-0.6-2.5-1.4q-0.4-0.2-0.7-0.4c-0.7 0.8-1.8 1.4-2.8 1.5-0.9-0.4-1.6-1.1-1.9-2-0.6 0.5-1.3 0.9-2.1 1q-0.4 0.2-0.8 0.4l-0.6 0.4c0-0.8-0.4-1.5-1.1-2-0.8-0.5-1.6-0.8-2.5-1-0.1-0.1-0.1-0.1-0.2-0.2q-0.3-0.1-0.6-0.3c-0.4-0.3-0.8-0.6-1.2-0.9q0.2-0.2 0.4-0.5c-0.7-0.1-1.5-0.4-2-1-1.3-1.6-1.6-3.9-2.8-5.7-1-1.3-2-3.8-0.4-3.9-2.7-1.2-4.9-3.5-6.2-6.2-3.4 0.7-7.1-2.5-6.9-6-3.2-0.8-5.7-3.6-6.2-6.8-3 1.3-7-1.4-6.9-4.7-0.5 1.7-2.5 2.8-4.2 2.3-1.7-0.4-2.8-2.3-2.4-4q-0.1 0-0.1 0-0.1 0-0.2 0.1-0.4 0.4-0.7 0.9c-0.7-1.3-2.3-2.1-3.8-2.7-0.6-0.3-1.2-0.3-1.8-0.1q0.3-0.4 0.5-0.7-0.4 0-0.8-0.1c-1.1-0.3-1.9-1.3-2.5-2.3-1-1.8-2-3.8-1.6-5.9 0.2-1.1 1-2.1 2-2.6z"/>
			<path id="&lt;Path&gt;" class="s9" d="m326.2 615c-4.6-0.6-9.5 0.3-13.7 2.4-2.5 1.2-4.8 2.9-7.2 4.3-7.1 4.1-15.4 5.8-23.6 6.4-1.3 0.1-2.6 0.2-3.7 0.9-1.1 0.7-1.7 2.2-1.1 3.3 5.3 0.6 10.7 0.4 16.1 0.2q13.4-0.5 26.9-1c3.7-0.1 7.5-0.3 11-1.5 0.5-0.2 1-0.4 1.3-0.8 0.2-0.4 0.3-0.9 0.3-1.4 0-5.6 0.6-11.9-6.3-12.8z"/>
			<path id="&lt;Path&gt;" class="s9" d="m381.4 604c-1 0.2-2.1 0.4-2.9 1-0.7 0.6-1 1.5-1.4 2.3-1.2 3.2-2 6.5-2.4 9.8-0.2 1.4-1.2 0.8-1.9 2.1-0.8 1.6-2.3 2.8-3.9 3.6-2.6 1.5-5.4 2.4-8.3 2.8-3.1 0.4-6.3 0.2-9.3 0.9-0.6 0.2-0.3 2.6-0.5 3.1-0.5 1.2 1.1 2 2.4 2.1 3.3 0.2 6.5-0.4 9.8-1.1 8.1-1.6 16.3-3.2 24.3-5.4 2.9-0.8 6.2-2 7.3-4.8 0.6-1.4 0.4-3.1 0.3-4.7q-0.8-7.3-1.5-14.7c-0.1-1.1-0.2-2.2-0.8-3.1-2-2.9-3.6 1-4.8 2.3-1.8 1.8-4 3.1-6.4 3.8z"/>
			<path id="&lt;Path&gt;" class="s10" d="m231.2 278c-1.7 1.4-1.9 4.9-2.4 6.7-1.9 7-3.9 14-6.5 20.8-0.2 0.4-0.3 0.9-0.1 1.3 0.1 0.3 0.5 0.4 0.8 0.6 1.9 0.8 5.9 0.9 7.9-0.2 0.7-0.4 1.3-1.1 1.8-1.8 1.7-2.6 3.1-5.4 4-8.4 0.9-2.9 1.5-6 2-9.1q0.7-4.1 1.4-8.3c0.1-0.3 0.1-0.7-0.1-1-0.6-1-3.4-0.2-4.4-0.4-1-0.2-1.8-0.9-2.8-0.9-0.7 0-1.2 0.3-1.6 0.7z"/>
			<path id="&lt;Path&gt;" class="s11" d="m272.6 315.7q2.3-4.8 4.7-9.5c4.9 0.8 8.7 4.8 12.3 8.3 4.9 4.9 10.2 9.4 15.9 13.4 3.3 2.4 7.2 4.7 11.2 4.1 4.9-0.8 8.1-5.6 10.6-9.9q14.2-24.4 28.5-48.7c7.5-12.9 15.9-8.2 21.3 3.4 4.9 10.5-1.4 22.3-6.3 31.8-6.2 12.3-13.1 24.2-20.7 35.7-4.4 6.8-9.7 13.9-17.6 15.8-3.8 0.8-7.8 0.3-11.6-0.7-12.1-3.5-21.7-12.5-30.6-21.4q-8.6-8.6-17-17.5c-0.6-0.7-1.3-1.4-1.4-2.3-0.1-0.9 0.3-1.7 0.7-2.5z"/>
			<path id="&lt;Path&gt;" class="s12" d="m108.2 622.2q5.9-1 11.9-1.7c-16.8-3.3-45.4 16.4-16.6 25.3 19 6 50.7 5.7 70.6 6.1 28.1 0.6 55.8-7 84.1-7.2 22.1-0.2 42.8-0.7 64.4-2.1 19.3-1.3 57 2.2 73.4-9 26-17.8-30.9-16-44.5-15.7-58.7 1.1-114.4 7-173.5 2.5-17.2-1.3-52.9 2.1-61.9 0.1z"/>
		</g>
		<g id="p3">
			<path id="&lt;Path&gt;" class="s13" d="m694.3 305.9c-1.1-0.6-2.2-1.2-2.9-2.1-1.4-1.8-1.4-4.2-1.3-6.4 0-0.9 0.2-2 1-2.3 1.1-0.5 2.3 1 3.5 0.5-0.2-0.9-0.4-1.9-0.1-2.9 0.3-0.9 1.2-1.8 2.2-1.6 0.6 1.6 0.2 3.6 1.2 5.1 1.2 1.8 3.8 1.8 5.7 2.9 1.6 0.9 2.6 2.4 3.6 3.9 1.7 2.4 0.2 5.1-2.6 5.4-3.4 0.3-7.4-1.1-10.3-2.5z"/>
			<path id="&lt;Path&gt;" class="s14" d="m777.1 315c-2.4 8.2-5.9 17.4-13.9 20.4-5.3 1.9-11.1 0.6-16.5-1-10.7-3.3-21-7.9-30.7-13.6-6.1-3.6-12.6-9.2-11.7-16.2 0.1-1.1 0.5-2.2 1.3-2.9 2-1.7 4.9 0 7 1.5 10.1 7.3 22.4 11.8 34.9 12.8 1.6 0.1 3.2 0.1 4.7-0.4 3.9-1.4 5.3-6 6.1-10.1 3.4-15.9 6.1-32.1 7.9-48.3 0.4-3.2 0.7-6.4 2.2-9.1 2.3-4.4 7.5-7 12.3-6.3 15.8 2.1 8.8 21.4 6.8 31.3q-4.3 21.2-10.4 41.9z"/>
			<path id="&lt;Path&gt;" class="s15" d="m740.9 363.3c0 0-11.8 223.1-11.9 257.3h12.9c0 0 20.9-204.4 31.2-214.7 0 0 4.3-0.8 6 0 1.6 0.8 4 203.8 1.2 216.5h13.2c0 0 23.8-208.9 22.3-257.7 0 0 1-24.5-32-22.3-33 2.2-39.6-3.4-42.9 20.9z"/>
			<path id="&lt;Path&gt;" class="s15" d="m742.7 617.3c-2.6-4.7-10.8-0.9-16.2-0.3-4.3 2.8-9 5-13.8 6.6-1.5 0.5-2.9 0.9-4 1.9-1.1 1.1-1.6 2.9-0.8 4.1 0.8 1 2.3 1.2 3.6 1.3 9.9 0.8 19.9 0.6 29.8-0.6 0.6 0 1.2-0.1 1.7-0.5 0.4-0.3 0.5-0.8 0.7-1.3 1.2-3.6 0.9-7.8-1-11.2z"/>
			<path id="&lt;Path&gt;" class="s15" d="m777.3 616.2c0 0 13.8-0.9 16 0 2.2 1 5.7 11.6 2.5 13.6-3.2 1.9-35.5 1.9-35.5 1.9 0 0-3.7-4.1 5.4-7.9 9.1-3.9 10.6-4.1 11.6-7.6z"/>
			<path id="&lt;Path&gt;" class="s16" d="m763.2 210.5c6.5 1 13.1-1.4 19.7-1.9-1-5.6 1.9-11.1 3.2-16.6 1.8-7.5 0.7-15.4-0.5-23-1-6.1-2.2-12.4-5.8-17.5-0.7-1-1.5-2-2.7-2.5-1-0.4-2.1-0.3-3.1-0.3-10 0.1-20.1 0.3-29.7 3.1-0.7 0.2-1.5 0.5-1.9 1.1-0.4 0.5-0.5 1.2-0.6 1.8-1.7 17.3 0.9 35 7.4 51.1 4.2 2.5 8.9 4 14 4.7z"/>
			<path id="&lt;Path&gt;" class="s17" d="m761.7 217.5q2.2-0.7 4.4-1.6 10.7-4.1 21.5-8.2c1.1-0.4 2.2-0.8 3.3-0.6 0.9 0.2 1.6 0.7 2.3 1.3 13.4 10.8 17 28.4 20.7 44.3 13 56.6 17.1 114.9 12.1 172.6-32 5.5-64.4 9-96.8 10.6 1.8 0 5.5-39.5 5.9-43.4 2.5-22.9 3.8-45.8 3.6-68.8-0.3-23 1-46 2.6-69q1-13.9 2.3-27.9c0.4-3.6 10.9-7.4 13.5-7.9 1.6-0.4 3.1-0.8 4.6-1.4z"/>
			<path id="&lt;Path&gt;" class="s18" d="m851.8 256.3c-0.4-0.9-0.9-1.8-1-2.8-0.2-1.7 0.8-3.2 1.4-4.7 2-5.1-1.1-11.5-6.3-13.1 4.3-2.5 9.3-6.6 7.9-11.3-0.9-3.1-4.2-4.8-6.9-6.6-2.7-1.7-5.4-4.8-4.3-7.9 0.7-1.8 2.7-3 3.6-4.7 2-3.7-1.4-8.1-5.2-9.9-3.7-1.8-8.1-2.3-11.4-4.9-4.8-3.8-5.5-10.8-5.3-17 0.3-6.2 0.9-12.8-2.3-18-5.7-9.2-18.6-8.5-27.7-11.1-1-0.2-2.3-0.9-3.8-1.7-6.9-6.1-15.6-9-25.2-8.1-5.6 0.5-11.2 1.5-16.7 1.6q-0.8 0.3-1.8 0.2c-3.5-0.3-8.9-0.3-11.1 3q-0.1 0.1-0.2 0.3 0.2 0.2 0.4 0.4c-0.3 0.2-0.5 0.3-0.8 0.4-1.2 2.8-0.8 6.6 0.9 8.8 1.1 1.4 1.3 3 0.8 4.4 12.6 5.6 25.7 4 39.3 0.6-2.4 8.2 0.1 22.3 2 30.5 4.7-2.9 8.6 2.1 7.7 7.2-0.7 3.3-4.2 3-5.1 5.9-0.4 1.8 0.7 6 1.5 8.8-0.7 2-1.1 4-1 6.1 0.1 2.6 1 5.3 0.5 7.8-0.9 4.4-5.6 8.1-4 12.3 0.6 1.5 2.1 2.7 2.4 4.4 0.1 1-0.1 2-0.4 2.9q-2.6 9.8-3.9 19.9c-0.2 1.8-0.5 3.6 0 5.3 1.5 5.9 8.9 7.5 14.9 8-1.6 2.7-0.2 6.5 2.3 8.4 2.5 1.9 5.8 2.4 9 2.7 5.7 0.4 11.4 0.1 16.9-0.8q0.4-2 0.7-4.1c3.8 2.5 8.9 2.8 12.9 0.6 3.9-2.1 6.5-6.5 6.5-11 3.6 1.4 7.9 0.5 10.6-2.2 2.7-2.7 3.6-7 2.2-10.6z"/>
			<path id="&lt;Compound Path&gt;" class="s8" d="m806.8 380.6c-0.3-1.4-0.7-3-2.2-3.8-0.9-0.6-2.2-0.7-3.3-0.9q-5-0.6-9.9-1.2c0.1-36.5 5.6-73 2.8-109.4-0.5-6.6-1.3-13.2-2.8-19.7-1.2-5-3.5-10.7-8.5-12.2-4.2-1.2-8.6 1.2-11.5 4.5-6.2 7.1-6.8 17.3-7.5 26.6-0.8 9.5-2 18.9-3.5 28.2-2.6 16.5-5.8 32.9-8.5 49.5-1.3 8.3-2.4 16.6-3.3 25 0 0.5-0.2 1.4-0.4 2.5q-1.3-0.1-2.7-0.1c-2.7 0-5.4 0.4-8 0.9-1 0.3-2 0.5-2.7 1.1-1.1 0.8-1.5 2.1-1.8 3.2-3 11.1-6.1 22.7-2.6 33.4 0.3 0.8 0.6 1.6 1.4 2.2 0.7 0.5 1.7 0.7 2.7 0.9q18.6 3.2 37.4 5.8c6.2 0.8 12.3 1.6 18.5 2.4 4.4 0.6 8.5 2.6 12-1.4 1.9-2.3 2.3-5.1 2.6-7.8q0.8-6.4 1.5-12.8c0.7-5.6 1.3-11.3 0.3-16.9zm-17-62c-1.5 7.8-1.4 15.8-1.3 23.7q0.3 16.1 0.5 32.1-17-2-34-4.1-1.2-0.1-2.4-0.2c-0.5-13.5 1.6-27 3.7-40.3q1.3-7.8 2.5-15.6c0.7-4.6 1.5-9.2 2.5-13.7 1-4.1 2.2-8.2 3-12.3 2.8-14.8 0.1-31.2 8.3-43.8 2.1-3.1 5.9-6.1 9.2-4.4 0.9 0.5 1.6 1.2 2.3 2 6.4 7.9 7.2 18.9 7.8 29.1q0.4 9.4 0.9 18.8c0.3 5.4 0.6 10.8-0.2 16.2-0.7 4.2-1.9 8.3-2.8 12.5z"/>
			<path id="&lt;Path&gt;" class="s13" d="m750.8 395.2c-0.1-0.2-0.3-0.3-0.4-0.5-0.8-0.7-1-1.9-0.8-3 0.2-1 0.7-2 1.2-2.9 1.8-3.4 3.1-7 3.9-10.7 0.1-0.6 0.2-1.2 0.6-1.7 0.6-1 1.9-1.4 3.1-1.3 1.2 0.1 2.4 0.6 3.5 0.9 1 0.3 2.6 0.5 2.8 1.7 0.2 1.3-0.2 3.2-0.3 4.6q-0.4 4.9-1.1 9.7-0.4 2.8-0.9 5.6c-0.3 1.2-0.5 2.5-1.1 3.7-0.6 1.6-1.8 3.1-3.3 4.1-0.8 0.6-1.7 1-2.7 1.1-2.3 0.2-4.4-1.7-5.1-3.9-0.7-2.3-0.2-4.7 0.5-6.9 0.1-0.2 0.1-0.4 0.1-0.5z"/>
			<path id="&lt;Path&gt;" class="s19" d="m772 365.5c1.4-7.4 3.2-14.6 5.3-21.9 1.7-5.6 3.8-11.1 5.6-16.8 3.5-10.8 5.7-21.9 7.8-33.1 2.8-13.9 5.4-28.5 1.7-42.2-1-3.7-2.6-7.4-5.3-10.1-2.8-2.7-7-4.1-10.6-2.7-2.8 1-4.8 3.5-6 6.2-1.1 2.7-1.5 5.7-1.9 8.6q-0.6 4.8-1.2 9.6c-0.7 5.3-1.4 10.6-1.7 16-0.6 10.2 0.2 20.5-0.2 30.7-0.8 20.1-6.3 39.7-11.3 59.1-1.1 4.3-2.1 8.7-1.4 13.1 0.2 1.2 6.9 2.9 8 3.2 1.9 0.5 3.2 1.8 4.9 0.4 2-1.6 3.2-6.4 3.6-8.7 0.7-3.7 2-7.6 2.7-11.4z"/>
			<path id="&lt;Compound Path&gt;" class="s0" d="m690.3 299.7c0-0.2 0-0.4 0-0.5q0.1-0.3 0.3-0.6 0 0.3 0 0.6-0.1 0.3-0.3 0.5z"/>
			<path id="&lt;Path&gt;" class="s12" d="m732.8 624.5c-12.9 2-44.3 0.5-47.2 15.4 32.8 16.5 102.2 10.5 132-11.4-10.9-16.6-70.4-9.4-84.8-2z"/>
			<path id="&lt;Path&gt;" class="s10" d="m682.3 307.2c-0.5-2.2 1.3-5.2 1.9-7 2.3-6.8 4.7-13.7 6.3-20.8 0.1-0.4 0.3-0.9 0.6-1.1 0.4-0.2 0.7-0.1 1.1-0.1 2.1 0.4 5.4 2.7 6.4 4.6 0.4 0.8 0.4 1.7 0.4 2.6 0 3.1-0.5 6.2-1.3 9.2-0.9 2.9-2.2 5.7-3.5 8.6q-1.8 3.8-3.6 7.6c-0.1 0.3-0.3 0.7-0.6 0.8-1.1 0.5-2.9-1.7-3.9-2.1-0.9-0.4-2-0.3-2.8-0.9-0.5-0.4-0.8-0.9-1-1.4z"/>
			<path id="&lt;Path&gt;" class="s13" d="m750.8 395.2c-0.1-0.2-0.3-0.3-0.4-0.5-0.8-0.7-1-1.9-0.8-3 0.2-1 0.7-2 1.2-2.9 1.8-3.4 3.1-7 3.9-10.7 0.1-0.6 0.2-1.2 0.6-1.7 0.6-1 1.9-1.4 3.1-1.3 1.2 0.1 2.4 0.6 3.5 0.9 1 0.3 2.6 0.5 2.8 1.7 0.2 1.3-0.2 3.2-0.3 4.6q-0.4 4.9-1.1 9.7-0.4 2.8-0.9 5.6c-0.3 1.2-0.5 2.5-1.1 3.7-0.6 1.6-1.8 3.1-3.3 4.1-0.8 0.6-1.7 1-2.7 1.1-2.3 0.2-4.4-1.7-5.1-3.9-0.7-2.3-0.2-4.7 0.5-6.9 0.1-0.2 0.1-0.4 0.1-0.5z"/>
			<path id="&lt;Path&gt;" class="s14" d="m772 365.5c1.4-7.4 3.2-14.6 5.3-21.9 1.7-5.6 3.8-11.1 5.6-16.8 3.5-10.8 5.7-21.9 7.8-33.1 2.8-13.9 5.4-28.5 1.7-42.2-1-3.7-2.6-7.4-5.3-10.1-2.8-2.7-7-4.1-10.6-2.7-2.8 1-4.8 3.5-6 6.2-1.1 2.7-1.5 5.7-1.9 8.6q-0.6 4.8-1.2 9.6c-0.7 5.3-1.4 10.6-1.7 16-0.6 10.2 0.2 20.5-0.2 30.7-0.8 20.1-6.3 39.7-11.3 59.1-1.1 4.3-2.1 8.7-1.4 13.1 0.2 1.2 6.9 2.9 8 3.2 1.9 0.5 3.2 1.8 4.9 0.4 2-1.6 3.2-6.4 3.6-8.7 0.7-3.7 2-7.6 2.7-11.4z"/>
		</g>
		<g id="p4">
			<path id="&lt;Path&gt;" class="s20" d="m947.3 433.7c0.4 7.7 0.9 15.3 1.5 23q1.3 17.2 2.6 34.4c0.3 3.6 0.7 7.5 3 10.4 0.2 0.3 0.5 0.6 0.8 0.7 1.2 0.5 2.1-1 2.5-2.2 4.7-14.5 11.6-28.2 20.6-40.5 2.3-3.2 4.8-6.3 8.1-8.2 2.5-1.5 5.4-2.3 8.2-3q8.5-2.3 17-4.5c3.3-0.9 6.7-1.8 9.3-4 2.1-1.8 3.4-4.2 4.7-6.6 6.5-12.1 13-24.3 16.3-37.5 2.3-9.7 2.8-19.8 2.6-29.8 0-0.9-0.1-2-0.4-2.9-0.6-1.3-1.6-2.2-2.8-3-4.3-2.7-9.7-3-14.8-3.2-19.4-0.7-39.3-0.9-58.3 3.9-5.8 1.4-15.8 3-18.5 9.2-3.4 7.7-2.2 19.5-2.6 27.7q-0.7 18.1 0.2 36.1z"/>
			<path id="&lt;Path&gt;" class="s20" d="m1057.5 608.5c3.1 5.3 8.5-3.5 13.6-6.6-9.1-18.5-10-37-19.2-55.5-2.9-5.9-8.3-14.5-10-20.7-2.2-7.6-4.1-12.9-4.5-20.7q-2-45.6-4.1-91.2c-0.1-2.6-0.2-5.2-1.4-7.5-3.5-6.7-13.4-7.3-21.5-7.1-1.7 0.1-3.7 0.2-4.9 1.3-1.5 1.3-1.6 3.3-1.6 5.1-0.3 24-15.8 1.6-16.1 25.6-0.1 8.5 8.7 63.8 10.7 72 3.4 14.7 13.3 31.5 22 44.2 13.5 19.6 23.7 38 37 61.1z"/>
			<path id="&lt;Path&gt;" class="s20" d="m986.8 420q-16.3-2.1-32.6-4.1c-0.6-1.3 2.1 0.1 2 1.5-5.3 45.5-2.6 91.6 2.4 137.2 2.2 19.7 2.5 39.4 0.7 59.1 4.4 0.4 12.1 1 16.6 1.5 7.6-61.3 12-122.7 19.6-184 0.4-3.1 0.6-6.7-1.8-8.9-1.8-1.6-4.4-2-6.9-2.3z"/>
			<path id="&lt;Path&gt;" class="s13" d="m905.9 316.9c-1.1-0.6-2.3-1.2-3-2.2-1.4-1.8-1.4-4.4-1.4-6.7 0.1-0.9 0.2-2 1.1-2.4 1.1-0.4 2.4 1.1 3.6 0.6-0.2-1-0.4-2.1-0.1-3.1 0.3-0.9 1.3-1.8 2.3-1.7 0.6 1.8 0.2 3.9 1.3 5.4 1.2 1.9 4 1.9 5.9 3 1.7 0.9 2.8 2.5 3.8 4.1 1.7 2.5 0.2 5.3-2.7 5.6-3.5 0.4-7.8-1.1-10.8-2.6z"/>
			<path id="&lt;Path&gt;" class="s13" d="m970.6 218.4c6.9 0 13.4-3.5 20.1-4.9-1.9-5.7 0.3-11.8 0.8-17.7 0.7-8-1.6-16-4-23.6-2-6.2-4.2-12.6-8.7-17.3-0.9-0.9-1.9-1.8-3.1-2.1-1.1-0.3-2.2-0.1-3.3 0.1-10.3 1.7-20.8 3.4-30.2 7.8-0.7 0.3-1.5 0.7-1.8 1.3-0.4 0.6-0.4 1.3-0.3 2 0.8 18.1 6.1 36 15.3 51.7 4.8 1.9 9.8 2.7 15.2 2.7z"/>
			<path id="&lt;Path&gt;" class="s21" d="m1038.5 370.5l0.1-0.1q0.1-0.1 0.2-0.2 0.3-0.4 0.5-0.7c2-3.1 3.7-6.4 5.5-9.7 2.9-37.8-1.7-75.9-14-112.9-3.8-11.6-7.6-24.4-21.6-32.3-0.7-0.4-1.5-0.8-2.4-0.9-1.1-0.2-2.3 0.1-3.4 0.4q-11.3 3-22.5 6-2.3 0.7-4.5 1.2-2.4 0.6-4.9 1c-2.7 0.4-13.7 3.1-14.1 5.8q-1.3 10.1-2.4 20.3c-1.7 16.7-3 33.4-2.8 50.2 0.3 16.7-1.1 33.4-3.7 50-0.2 1.3-1.1 8-2.2 14.9q0.1 0.2 0.2 0.4 1.4 2.7 3.2 5.3c0.1 0.1 0.3 0.4 0.5 0.7q0.2 0.2 0.3 0.3 2 2.2 4.2 4.2c0.1 0.1 0.3 0.3 0.6 0.5-2.1-1.9 0.1 0 0.7 0.4q2.3 1.5 4.8 2.8c0.4 0.2 2.1 0.9 2.2 1q0.3 0.1 0.6 0.2c23.5-1.3 47-3.2 70.3-5.8 1.5-1 3.1-2.2 4.6-3z"/>
			<path id="&lt;Path&gt;" class="s22" d="m992.4 326.5c-2.5 8.5-6.2 18.1-14.6 21.2-5.4 2-11.6 0.7-17.2-1-11.1-3.5-21.9-8.2-32-14.2-6.4-3.8-13.2-9.6-12.3-17 0.2-1.1 0.6-2.3 1.4-3 2.1-1.7 5.1 0 7.3 1.6 10.6 7.7 23.4 12.3 36.5 13.3 1.6 0.2 3.3 0.2 4.9-0.3 4-1.5 5.5-6.3 6.4-10.6 3.6-16.6 6.3-33.5 8.2-50.5 0.4-3.2 0.8-6.6 2.3-9.5 2.4-4.5 7.8-7.2 12.9-6.5 16.5 2.2 9.1 22.3 7 32.6q-4.4 22.2-10.8 43.9z"/>
			<path id="&lt;Path&gt;" class="s23" d="m960.4 612c-0.2 0-0.5 0-0.8 0-0.3 0.1-0.5 0.3-0.7 0.5-1 1-1.7 2-2.1 3.2-0.3 1-0.4 2.1-1.2 3-0.8 1.1-2.4 1.7-3.9 2.2-4 1.4-8.3 2.3-12.6 2.8-1 0.1-2 0.1-2.9 0.4-2.5 0.9-3.1 3.1-3.4 5.1-0.1 0.5-0.1 1.2 0.6 1.5 0.3 0.2 0.8 0.2 1.2 0.1 12.6-0.1 17.9-0.2 30.5-0.3 3-0.1 6.2-0.2 9.1-0.7 0.4-0.1 0.8-0.2 1.1-0.4 0.4-0.2 0.5-0.5 0.6-0.8 1.6-4.4 1.8-9.1 1.3-13.5-0.1-1.4-0.3-2.5-2.5-2.8-3.2-0.4 0.3-0.1-2.9-0.2q-5.7-0.1-11.4-0.1z"/>
			<path id="&lt;Path&gt;" class="s23" d="m1057.9 607.5c-0.5 0.3-1 0.6-1.3 1-0.3 0.4-0.4 0.8-0.5 1.3-0.3 2.5 1 5.2 0.4 7.8-0.5 1.6-1.7 3-2.9 4.2q-3 2.9-6.3 5.6c-1 0.7-2 1.5-2.4 2.7-0.6 1.9 1.3 4.3 3.4 4 0.8-0.1 1.5-0.5 2.1-0.9q13.8-8.1 26.9-17.2c1-0.8 2.2-1.7 2.3-3 0-0.7-0.3-1.4-0.6-2q-1.6-3.3-3.6-6.4c-0.7-1.1-1.3-2.2-2.1-3.2-1.1-1.4-1.1-1.5-2.6-0.7-4.2 2.2-8.5 4.6-12.8 6.8z"/>
			<path id="&lt;Path&gt;" class="s24" d="m1010.1 175c0-3.9-2.2-7.3-4.1-10.7-3.2-5.5-5.8-11.5-9.7-16.7-3.9-5.1-9.7-9.3-16.1-9.4-2.7-0.1-5.3 0.6-7.9 1.2-11.1 2.8-22.3 5.7-32.3 11.1-1.3 0.7-2.6 1.4-3.4 2.6-0.6 1.1-0.9 2.4-1.1 3.6q-0.4 2.7-0.9 5.3c-0.1 1.1-0.3 2.2-0.1 3.3 0.7 3.4 4.7 4.9 8.1 5 3.4 0.2 6.8-0.3 10.1-0.9 3.3-0.4 10.8-3.2 13.5-0.3 1.3 1.4 1 3.8 1.9 5.5 1 2.1 3.1 3.4 4.5 5.2 1.4 1.7 2.4 4.2 1.4 6.2 1.9-1.2 4.2-1.5 6.3-1 0.8 0.1 1.6 0.4 2.1 1 0.9 1.2 0.5 3-0.5 4.1-1 1.1-2.5 1.6-3.9 2.1 6.1 5.8 8.5 15.3 5.7 23.3 4.7-0.5 8.5-3.8 11.7-7.2 2.9-3.2 5.6-6.6 7.4-10.4 0.8-1.8 1.4-3.7 2.1-5.5q1.9-5.6 3.8-11.1c0.7-2.1 1.4-4.1 1.4-6.3z"/>
			<path id="&lt;Path&gt;" class="s10" d="m900.2 323.7c-1.4-1.6-1.4-5.2-1.7-7-1.2-7.1-2.5-14.3-4.4-21.3-0.2-0.4-0.3-0.9 0-1.2 0.1-0.3 0.5-0.5 0.8-0.6 2-0.7 6-0.3 7.9 0.9 0.7 0.5 1.2 1.3 1.6 2 1.5 2.8 2.5 5.7 3.2 8.8 0.7 3 0.9 6.1 1.1 9.2q0.3 4.2 0.6 8.4c0 0.3 0 0.7-0.2 1-0.7 1-3.4-0.1-4.5 0-0.9 0.1-1.8 0.7-2.8 0.6-0.6-0.1-1.2-0.4-1.6-0.8z"/>
			<path id="&lt;Path&gt;" class="s12" d="m1083.1 633.6c6.2-15.3-155.5-11.6-174.7 7.4 16.9 16.5 64.1 2.9 85.2 1.9 15.6-0.8 82.3 8.4 89.5-9.3z"/>
		</g>
		<g id="p2">
			<path id="&lt;Path&gt;" class="s25" d="m563.4 140.6c-39.5 9.8-9.4 99 22.1 83.6 39-19-10.3-97.7-22.1-82.1z"/>
			<path id="&lt;Path&gt;" class="s26" d="m558.1 165.4c3.7-5.4 3.2-8.4 8.9-2.4 3.9 4.2 5.3 11.4 8.9 15.8-0.2-4.6 0.3-10.7 6.1-9.8 9.5 1.5 2.1 9.5 1.7 13.4-0.8 6.3 8.6 21.6 14.1 25 3.4-4.2 7.2-21.7 7.4-27.9 0.4-12.3-6.2-30.3-12.7-40.6-10.9-17.2-24.6-8.4-36.9 0.2-6.6 4.6-19.5 9-22.9 16.8-3.2 7.5 3.5 16.6 10.8 18.7 8.2 2.3 10.4-3.4 14.6-9.2z"/>
			<path id="&lt;Path&gt;" class="s8" d="m548.7 381.7c-4.2 0.1-8.6 0.2-12 2.1-5.5 3.1-3.8 9.3-4.2 14.7-1.2 17.8-4.4 35.4-3.8 53.2 0.1 1.5 0.3 3.2 1.7 4.3 0.9 0.6 2.1 0.8 3.3 1 31.3 5.7 63.5 8.2 95.6 7.5 1.1 0 2.3 0 3.3-0.5 1.7-0.8 2.2-2.5 2.6-4 4.9-19.9 5.5-39.9 7.1-60.2 0.2-2.6 0.3-5.3-1.1-7.7-2.7-4.8-7.7-4.2-13.2-5-8.3-1.3-21-2.3-29.3-3.1-16.6-1.6-33.3-2.4-50-2.3z"/>
			<path id="&lt;Path&gt;" class="s10" d="m501.2 301.9c0-0.6 0.2-1.2 0.7-1.5 0.3-0.2 10.7-0.2 11.1-0.3q5.1-0.7 10.2-1.5c1.1 1.5 1.2 3.3 1.3 5 0.8 8.1 3.7 16.1 8.6 23.3l0.4 8.2c1 0.7 0.7 2.1-0.2 2.9-0.9 0.7-2.3 1.1-3.6 1.3q-2.3 0.3-4.7 0.3c-0.4 0-0.9-0.1-1.3-0.3-0.4-0.1-0.6-0.5-0.8-0.8-5.5-8.2-8-17.4-10.4-26.5-0.9-3.3-11.8-6.7-11.3-10.1z"/>
			<path id="&lt;Path&gt;" class="s25" d="m516.6 325.4c-1-0.5-2.1-1.2-2.8-2.1-1.3-1.7-1.3-4.1-1.2-6.2 0-0.9 0.1-1.9 0.9-2.3 1.1-0.4 2.3 1 3.4 0.6-0.2-1-0.3-2-0.1-2.9 0.3-0.9 1.2-1.7 2.2-1.6 0.6 1.6 0.2 3.6 1.2 5 1.2 1.8 3.7 1.8 5.6 2.9 1.5 0.8 2.5 2.3 3.5 3.8 1.6 2.4 0.2 5-2.6 5.3-3.2 0.3-7.2-1.1-10.1-2.5z"/>
			<path id="&lt;Path&gt;" class="s27" d="m612.9 628.7c5.3-59.7 10.3-150.3 15.6-210-15.2-1.6-31.5 20-46.7 20.3-4.8 0.1-11.1-13.4-12.1-7.8-0.6 3.8 5.3 22.4 5.9 26.4 4.1 27.6 8.8 55.4 10.4 83.4 0.5 8.1 0.2 16.3 0.4 24.4 0.3 13.8 1.7 27.5 3.5 41.2 0.7 5.3 4.2 15.3 2.9 20.4-1.1 4.4-7.8 6.7-12.1 8.5-1.5 0.6-3 1.1-4.1 2.3-1 1.1-1.5 3-0.5 4.2 0.9 1.1 2.4 1.2 3.8 1.2 10.4 0.1 20.9-0.8 31.1-2.7 0.6-0.1 1.3-0.2 1.7-0.7 0.4-0.3 0.5-0.8 0.7-1.3 0.8-3.2 0.5-6.8-0.9-9.8q0.2 0 0.4 0z"/>
			<path id="&lt;Path&gt;" class="s28" d="m582.6 239q2.2-0.8 4.3-1.6 10.5-4 21-8.1c1.1-0.4 2.2-0.8 3.3-0.5 0.8 0.1 1.5 0.7 2.2 1.2 13.1 10.6 16.7 27.8 20.3 43.4 12.7 55.3 16.7 112.4 11.8 168.8-31.3 5.4-63 8.9-94.7 10.4 1.7 0 5.4-38.7 5.8-42.5 2.4-22.3 3.7-44.8 3.5-67.2-0.3-22.6 0.9-45 2.6-67.6q1-13.6 2.2-27.2c0.3-3.6 10.6-7.3 13.2-7.8 1.6-0.4 3.1-0.8 4.5-1.3z"/>
			<path id="&lt;Path&gt;" class="s28" d="m597.7 334.3c-2.4 8.1-5.8 17-13.7 19.9-5.1 2-10.8 0.7-16.1-0.9-10.5-3.2-20.5-7.7-30-13.3-5.9-3.5-12.4-9-11.5-15.9 0.2-1.1 0.5-2.2 1.3-2.8 2-1.6 4.8 0 6.8 1.4 10 7.2 21.9 11.6 34.2 12.6 1.5 0.1 3.1 0.1 4.6-0.4 3.8-1.3 5.2-5.9 6-9.8 3.3-15.7 5.9-31.5 7.7-47.3 0.4-3.1 0.7-6.3 2.2-9 2.2-4.2 7.2-6.7 12-6.1 15.4 2.1 8.5 21 6.6 30.6q-4.2 20.7-10.1 41z"/>
			<path id="&lt;Path&gt;" class="s12" d="m657.7 635.6c4.3-2.7-72.3-9.1-81.8-8-14 1.5-43.5 2.3-52.9 11.2-23.8 22.6 28.9 11.8 39.9 10.9 32.3-2.5 67.6 3.3 94.8-14.1z"/>
		</g>
	</g>
</svg>
         `
      }} />   
      <TextBox>
        <h2>Community</h2>
        <p>Community Governance In Effect from Day ZERO</p>
      </TextBox>
      <BottomBlur />
    </Container>
  );
}

export default CommunityBanner;
