import React from "react";
import styled from "styled-components";


const DetailsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  line-height: 2.5;
  
  @media (max-width: 1024px) {
    line-height: 2.2;
  }
  
  @media (max-width: 768px) {
    line-height: 2;
  }
`;

const MediumText = styled.span`
  font-size: 18px;
  color: #000000;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.colorstatus};
  
  @media (max-width: 1024px) {
    font-size: 16px;
  }
  
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const SmallText = styled.span`
  font-size: 11px;
  color: #000000;
  font-weight: 700;
  text-transform: uppercase;
  
  @media (max-width: 1024px) {
    font-size: 10px;
  }
  
  @media (max-width: 768px) {
    font-size: 9px;
  }
`;

const SpacedHorizontalContainer = styled.div`
  width: 100%;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  margin: 8px 0;
`;



const TaskList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 8px 0;
  
  @media (max-width: 768px) {
    margin: 6px 0;
  }
`;

const TaskItem = styled.li`
  color: #000000;
  font-size: 10px;
  font-weight: 100;
  margin: 5px 0;
  line-height: 2.5;
  
  @media (max-width: 1024px) {
    font-size: 11px;
    line-height: 2.2;
  }
  
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 2;
    margin: 3px 0;
  }
`;

export function ShoesDetails({ quarter, status, tasks, colorstatus }) {
  return (
    <DetailsContainer>
      <SpacedHorizontalContainer>
        <MediumText colorstatus={colorstatus}>{status}</MediumText>
      </SpacedHorizontalContainer>
      <SmallText>{quarter}</SmallText>
      <TaskList>
        {tasks.map((task, index) => (
          <TaskItem key={index}>{task}</TaskItem>
        ))}
      </TaskList>
    </DetailsContainer>
  );
}