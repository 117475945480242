import React, { useState } from 'react';
import { useProposalContext } from '../context/ProposalContext';
import styled from 'styled-components';

// Define mediaQueries before styled components
const mediaQueries = {
  desktop: '@media (max-width: 1200px)',
  tablet: '@media (max-width: 768px)',
  mobile: '@media (max-width: 480px)',
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Press Start 2P', cursive;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  ${mediaQueries.tablet} {
    padding: 1.5rem;
    transform: translateY(-15%);
  }

  ${mediaQueries.mobile} {
    padding: 1rem;
    transform: translateY(-15%);

  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #FF6B6B, #4ECDC4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${mediaQueries.tablet} {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  ${mediaQueries.mobile} {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const ProposalGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  width:80%;
  margin: 0 auto;   
  height:70%;
`;

const ProposalCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;

  h3 {
    margin: 0 0 1rem 0;
    font-size: 1.1rem;
    
    ${mediaQueries.mobile} {
      font-size: 0.9rem;
    }
  }

  p {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
    line-height: 1.5;
    
    ${mediaQueries.mobile} {
      font-size: 0.8rem;
      margin-bottom: 1rem;
    }
  }

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.1);
  }
`;

const ProposalStatus = styled.div`
  display: inline-block;
  padding: 0.25rem 1rem;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 1rem;
  background: ${props => props.active ? 'rgba(46, 213, 115, 0.15)' : 'rgba(255, 71, 87, 0.15)'};
  color: ${props => props.active ? '#2ed573' : '#ff4757'};
`;

const ProposalTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #000000;
`;

const ProposalInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  color: black;
  font-size: 0.875rem;
`;

const ProgressBar = styled.div`
  width: ${props => props.width}%;
  height: 100%;
  background: #FFDE4D;
  transition: width 0.3s ease;
`;

const VoteButton = styled.button`
  width: 100%;
  padding: 0.8rem;
  background: ${props => props.disabled ? '#ddd' : '#FFDE4D'};
  border: none;
  border-radius: 8px;
  font-family: 'Press Start 2P', cursive;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.3s ease;

  &:hover {
    transform: ${props => props.disabled ? 'none' : 'translateY(-2px)'};
    box-shadow: ${props => props.disabled ? 'none' : '0 4px 8px rgba(0,0,0,0.1)'};
  }
`;

const ProposalDescription = styled.p`
  font-size: 0.875rem;
  margin-bottom: 1rem;
  color: #000000;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 16px;
  width: 90%;
  max-width: 500px;
  box-sizing: border-box;

  ${mediaQueries.tablet} {
    padding: 1.5rem;
    width: 95%;
  }

  ${mediaQueries.mobile} {
    padding: 1rem;
    width: 95%;
    margin: 0 1rem;
  }
`;

const VoteOptions = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  ${mediaQueries.mobile} {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const VoteOption = styled.button`
  flex: 1;
  padding: 1rem;
  border: 2px solid ${props => props.selected ? '#4ECDC4' : '#ddd'};
  border-radius: 8px;
  background: ${props => props.selected ? 'rgba(78, 205, 196, 0.1)' : 'white'};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: #4ECDC4;
  }
`;

const ReasonInput = styled.textarea`
  width: 100%;
  padding: 1rem;
  border: 2px solid #ddd;
  border-radius: 8px;
  margin: 1rem 0;
  min-height: 100px;
  font-family: inherit;
`;


const ModalButtons = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  font-family: 'Press Start 2P', cursive;
`;

const ModalButton = styled.button`
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  background: ${props => props.primary ? 'linear-gradient(90deg, #4ECDC4, #2ed573)' : '#ddd'};
  color: ${props => props.primary ? 'white' : 'black'};
    font-family: 'Press Start 2P', cursive;

`;
const EmptyState = styled.div`
  text-align: center;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  color: black;
  font-size: 1.2rem;
`;

const CreateButton = styled.button`
  background: #FFDE4D;
  border: none;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-family: 'Press Start 2P', cursive;
  cursor: pointer;
  margin-bottom: 2rem;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
`;

const ProposalForm = styled.form`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  
  ${mediaQueries.tablet} {
    max-width: 90%;
  }

  ${mediaQueries.mobile} {
    max-width: 100%;
  }
`;

const Input = styled.input`
  padding: 1rem;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.9rem;

  &:focus {
    outline: none;
    border-color: #FFDE4D;
  }
`;

const TextArea = styled.textarea`
  padding: 1rem;
  border: 2px solid #ddd;
  border-radius: 8px;
  min-height: 100px;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.9rem;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: #FFDE4D;
  }
`;

const SubmitButton = styled.button`
  background: #FFDE4D;
  border: none;
  padding: 1rem;
  border-radius: 8px;
  font-family: 'Press Start 2P', cursive;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
`;

const Progress = styled.div`
  width: 100%;
  height: 10px;
  background: #eee;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 1rem;
`;
const EndDate = styled.div`
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 1rem;
`;  

const VoteInfo = styled.div`
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 1rem;
`;

const ProposalsList = styled.div`
    display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 0.5fr));
  gap: 2rem;
  width:80%;
  margin: 0 auto;   
  height:70%;

  ${mediaQueries.tablet} {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
  }

  ${mediaQueries.mobile} {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const VoteHistoryButton = styled.button`
  background: transparent;
  border: 2px solid #FFDE4D;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.7rem;
  cursor: pointer;
  margin-top: 1rem;
  transition: all 0.3s ease;

  &:hover {
    background: #FFDE4D;
  }

  ${mediaQueries.mobile} {
    font-size: 0.6rem;
    padding: 0.4rem 0.8rem;
  }
`;

const VoteHistoryModal = styled(Modal)``;

const VoteHistoryContent = styled(ModalContent)`
  max-height: 80vh;
  overflow-y: auto;
`;

const VoteHistoryItem = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #eee;
  
  &:last-child {
    border-bottom: none;
  }
`;

const VoteChoice = styled.span`
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-right: 0.5rem;
  background: ${props => props.choice === 'yes' ? '#4ECDC4' : '#FF6B6B'};
  color: white;
`;

const VoteTimestamp = styled.span`
  font-size: 0.8rem;
  color: #666;
`;

const VoteReason = styled.p`
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #333;
`;

const NotificationModal = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  z-index: 1000;
  max-width: 300px;

  ${mediaQueries.tablet} {
    bottom: 1.5rem;
    right: 1.5rem;
  }

  ${mediaQueries.mobile} {
    bottom: 1rem;
    right: 1rem;
    left: 1rem;
    max-width: none;
  }
`;

const NotificationIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const NotificationText = styled.p`
  margin: 1rem 0;
  font-size: 0.9rem;
  color: #333;
  line-height: 1.5;
`;

const NotificationButton = styled(ModalButton)`
  margin-top: 1rem;
  background: #FFDE4D;
  color: black;
  padding: 0.75rem 2rem;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const OpeningVote = () => {
  const { 
    proposals, 
    loading, 
    error, 
    createProposal, 
    voteOnProposal,
    setProposals 
  } = useProposalContext();
  const [showNewProposalForm, setShowNewProposalForm] = useState(false);
  const [showVoteModal, setShowVoteModal] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState(null);
  const [voteData, setVoteData] = useState({
    choice: null, // 'yes' or 'no'
    reason: ''
  });
  const defaultVotePower = 100;
  const [showVoteHistory, setShowVoteHistory] = useState(false);
  const [selectedVoteHistory, setSelectedVoteHistory] = useState(null);
  const [showThankYou, setShowThankYou] = useState(false);
  // Get connected wallet address from Phantom
  const getWalletAddress = () => {
    const provider = window.solana;
    return provider?.publicKey?.toString();
  };

  // const handleCreateProposal = async (e) => {
  //   e.preventDefault();
  //   const walletAddress = getWalletAddress();
    
  //   if (!walletAddress) {
  //     alert('Please connect your Phantom wallet first');
  //     return;
  //   }

  //   const success = await createProposal(newProposal, walletAddress);
  //   if (success) {
  //     setShowNewProposalForm(false);
  //     setNewProposal({ title: '', description: '', endDate: '' });
  //   }
  // };

  const handleVoteClick = (proposal) => {
    setSelectedProposal(proposal);
    setShowVoteModal(true);
    setVoteData({ choice: null, reason: '' });
  };

  const handleVoteSubmit = async () => {
    const walletAddress = getWalletAddress();
    
    if (!walletAddress) {
      alert('Please connect your Phantom wallet first');
      return;
    }

    if (!voteData.choice) {
      alert('Please select Yes or No');
      return;
    }

    if (!voteData.reason.trim()) {
      alert('Please provide a reason for your vote');
      return;
    }

    try {
      const success = await voteOnProposal(selectedProposal.id, walletAddress, voteData);
      if (success) {
        // Create updated proposals array
        const updatedProposals = proposals.map(proposal => {
          if (proposal.id === selectedProposal.id) {
            return {
              ...proposal,
              voters: [...(proposal.voters || []), walletAddress],
              voteHistory: [
                ...(proposal.voteHistory || []),
                {
                  voter: walletAddress,
                  choice: voteData.choice,
                  reason: voteData.reason,
                  timestamp: new Date().toISOString()
                }
              ]
            };
          }
          return proposal;
        });

        // Update the proposals state
        setProposals(updatedProposals);
        
        // Close modal and reset state
        setShowVoteModal(false);
        setSelectedProposal(null);
        setVoteData({ choice: null, reason: '' });

        // Show thank you notification
        setShowThankYou(true);
        setTimeout(() => setShowThankYou(false), 3000); // Auto-hide after 3 seconds
      }
    } catch (error) {
      console.error('Error submitting vote:', error);
      alert('Failed to submit vote. Please try again.');
    }
  };

  const handleVoteHistoryClick = (proposal) => {
    setSelectedVoteHistory(proposal);
    setShowVoteHistory(true);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Container>
      <Title>Opening Proposals</Title>
      
      <ProposalsList>
        {proposals.map(proposal => (
          <ProposalCard key={proposal.id}>
            <h3>{proposal.title}</h3>
            <p>{proposal.description}</p>
            <EndDate>{proposal.endDate.toDate().toLocaleDateString()}</EndDate>
            <VoteInfo>
              Total Votes: {proposal.voteHistory?.length || 0} | 
              Target Votes: {proposal.TargetVotes || 100} (
              {Math.round((proposal.voteHistory?.length || 0) / (proposal.targetVotePower || 100) * 100)}%)
            </VoteInfo>
            <Progress>
              <ProgressBar 
                width={
                  Math.min(
                    Math.round((proposal.voteHistory?.length || 0) / (proposal.targetVotePower || 100) * 100),
                    100
                  )
                } 
              />
            </Progress>
            <VoteButton 
              onClick={() => handleVoteClick(proposal)}
              disabled={proposal.voters?.includes(getWalletAddress())}
            >
              {proposal.voters?.includes(getWalletAddress()) ? 'Voted' : 'Vote'}
            </VoteButton>
            <VoteHistoryButton onClick={() => handleVoteHistoryClick(proposal)}>
              View Vote History
            </VoteHistoryButton>
          </ProposalCard>
        ))}
      </ProposalsList>

      {showVoteModal && selectedProposal && (
        <Modal onClick={() => setShowVoteModal(false)}>
          <ModalContent onClick={e => e.stopPropagation()}>
            
            <ProposalDescription>Your Vote Power: {defaultVotePower}</ProposalDescription>
            
            <VoteOptions>
              <VoteOption 
                selected={voteData.choice === 'yes'}
                onClick={() => setVoteData(prev => ({ ...prev, choice: 'yes' }))}
              >
                Yes
              </VoteOption>
              <VoteOption 
                selected={voteData.choice === 'no'}
                onClick={() => setVoteData(prev => ({ ...prev, choice: 'no' }))}
              >
                No
              </VoteOption>
            </VoteOptions>

            <ReasonInput
              placeholder="Please provide a reason for your vote..."
              value={voteData.reason}
              onChange={(e) => setVoteData(prev => ({ ...prev, reason: e.target.value }))}
            />

            <ModalButtons>
              <ModalButton onClick={() => setShowVoteModal(false)}>
                Cancel
              </ModalButton>
              <ModalButton primary onClick={handleVoteSubmit}>
                Submit Vote
              </ModalButton>
            </ModalButtons>
          </ModalContent>
        </Modal>
      )}

      {showVoteHistory && selectedVoteHistory && (
        <VoteHistoryModal onClick={() => setShowVoteHistory(false)}>
          <VoteHistoryContent onClick={e => e.stopPropagation()}>
            <h3>Vote History</h3>
            {selectedVoteHistory.voteHistory?.map((vote, index) => (
              <VoteHistoryItem key={index}>
                <VoteChoice choice={vote.choice}>
                  {vote.choice.toUpperCase()}
                </VoteChoice>
                <VoteTimestamp>
                  {new Date(vote.timestamp).toLocaleString()}
                </VoteTimestamp>
                <div>Voter: {vote.voter.slice(0, 6)}...{vote.voter.slice(-4)}</div>
                <VoteReason>{vote.reason}</VoteReason>
              </VoteHistoryItem>
            )) || <p>No votes yet</p>}
            <ModalButtons>
              <ModalButton onClick={() => setShowVoteHistory(false)}>
                Close
              </ModalButton>
            </ModalButtons>
          </VoteHistoryContent>
        </VoteHistoryModal>
      )}

      {showThankYou && (
        <NotificationModal>
          <NotificationIcon>🎉</NotificationIcon>
          <NotificationText>
            Thanks for voting!<br />
            Your vote has been recorded successfully.
          </NotificationText>
          <NotificationButton onClick={() => setShowThankYou(false)}>
            Got it!
          </NotificationButton>
        </NotificationModal>
      )}
    </Container>
  );
};

export default OpeningVote;
