import React, { createContext, useContext, useState, useEffect } from 'react';
import { collection, addDoc, getDocs, updateDoc, doc, arrayUnion, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

const ProposalContext = createContext();

export const useProposalContext = () => {
  const context = useContext(ProposalContext);
  if (!context) {
    throw new Error('useProposalContext must be used within a ProposalProvider');
  }
  return context;
};

export const ProposalProvider = ({ children }) => {
  const [proposals, setProposals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch proposals using getDocs
  useEffect(() => {
    const fetchProposals = async () => {
      console.log('Starting to fetch proposals...');
      try {
        const querySnapshot = await getDocs(collection(db, "proposals"));
        const proposalsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        
        console.log('Fetched proposals:', proposalsData);
        setProposals(proposalsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching proposals:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchProposals();
  }, []);

  // Create new proposal
  const createProposal = async (proposalData, walletAddress) => {
    console.log('Creating new proposal with data:', proposalData);
    try {
      const newProposal = {
        ...proposalData,
        status: 'active',
        createdAt: new Date(),
        createdBy: walletAddress,
        // votes: 0,
        // progress: 0,
        voters: [],
        totalVotingPower: 0,
      };
      
      const docRef = await addDoc(collection(db, 'proposals'), newProposal);
      console.log('Created proposal with ID:', docRef.id);
      
      // Fetch updated proposals
      const querySnapshot = await getDocs(collection(db, "proposals"));
      const updatedProposals = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProposals(updatedProposals);
      
      return true;
    } catch (error) {
      console.error('Error creating proposal:', error);
      return false;
    }
  };

  // Vote on proposal
  const voteOnProposal = async (proposalId, walletAddress, voteData) => {
    console.log('Voting on proposal:', proposalId, 'with data:', voteData);
    try {
      const proposalRef = doc(db, 'proposals', proposalId);
      const proposalDoc = await getDoc(proposalRef);
      
      if (!proposalDoc.exists()) {
        throw new Error('Proposal not found');
      }

      const proposalData = proposalDoc.data();
      
      if (proposalData.voters.includes(walletAddress)) {
        throw new Error('Already voted');
      }

      // Calculate new vote counts
      const newTotalVotes = proposalData.votes + 1;
      const voteValue = voteData.choice === 'yes' ? 1 : 0;
      const newYesVotes = (proposalData.yesVotes || 0) + voteValue;
      const newProgress = Math.round((newYesVotes / newTotalVotes) * 100);

      // Create vote record
      const voteRecord = {
        voter: walletAddress,
        choice: voteData.choice,
        reason: voteData.reason,
        timestamp: new Date(),
      };

      // Update proposal document
      await updateDoc(proposalRef, {
        // votes: newTotalVotes,
        yesVotes: newYesVotes,
        // progress: newProgress,
        voters: arrayUnion(walletAddress),
        voteHistory: arrayUnion(voteRecord)
      });

      // Fetch updated proposals
      const querySnapshot = await getDocs(collection(db, "proposals"));
      const updatedProposals = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProposals(updatedProposals);

      return true;
    } catch (error) {
      console.error('Error voting on proposal:', error);
      return false;
    }
  };

  const value = {
    proposals,
    setProposals,
    loading,
    error,
    createProposal,
    voteOnProposal
  };

  return (
    <ProposalContext.Provider value={value}>
      {children}
    </ProposalContext.Provider>
  );
}; 