import React from 'react';
import styled from 'styled-components';
import { useWallet } from '../context/WalletContext';

const WalletButton = () => {
  const { connected, publicKey, connectWallet, disconnectWallet } = useWallet();

  const handleClick = () => {
    if (connected) {
      disconnectWallet();
    } else {
      connectWallet();
    }
  };

  return (
    <StyledButton onClick={handleClick}>
      {connected ? (
        <ButtonContent>
          <span>{`${publicKey.slice(0, 4)}...${publicKey.slice(-4)}`}</span>
          <DisconnectIcon>×</DisconnectIcon>
        </ButtonContent>
      ) : (
        'Connect Wallet'
      )}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  background: #FFDE4D;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #000;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }

  @media (max-width: 768px) {
    padding: 0.6rem 1rem;
    font-size: 0.7rem;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const DisconnectIcon = styled.span`
  font-size: 1.2rem;
  opacity: 0.7;
`;

export default WalletButton; 