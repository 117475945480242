import React from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { FaDiscord, FaTwitter, FaTelegram } from 'react-icons/fa';

// Create a color transition keyframe animation
const colorTransition = keyframes`
  0% { color: #333; }
  50% { color: #FFDE4D; }
  100% { color: #333; }
`;

const SocialContainer = styled(motion.div)`
  position: fixed;
  left: 3%;
  top: 40%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  z-index: 100;
  font-family: 'Press Start 2P', cursive;

`;

const SocialIcon = styled(motion.a)`
  color: #333;
  font-size: 2rem;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.2);
    animation: ${colorTransition} 2s infinite;
  }

  // Add different animation delays for each icon
  &:nth-child(1) { animation-delay: 0s; }
  &:nth-child(2) { animation-delay: 0.3s; }
  &:nth-child(3) { animation-delay: 0.6s; }
`;

const fadeIn = {
  hidden: { opacity: 0, x: -20 },
  visible: { 
    opacity: 1, 
    x: 0,
    transition: {
      duration: 0.6,
      delay: 1.5
    }
  }
};

const SocialLinks = () => {
  return (
    <SocialContainer
      initial="hidden"
      animate="visible"
      variants={fadeIn}
    >
      <SocialIcon 
        href="https://discord.gg/evmt6KfC" 
        target="_blank"
        rel="noopener noreferrer"
        whileHover={{ scale: 1.2 }}
        variants={fadeIn}
      >
        <FaDiscord />
      </SocialIcon>
      <SocialIcon 
        href="https://x.com/cath_live_sol" 
        target="_blank"
        rel="noopener noreferrer"
        whileHover={{ scale: 1.2 }}
        variants={fadeIn}
      >
        <FaTwitter />
      </SocialIcon>
      <SocialIcon 
        href="https://t.me/+i-KJXfsbJD1kYjM1" 
        target="_blank"
        rel="noopener noreferrer"
        whileHover={{ scale: 1.2 }}
        variants={fadeIn}
      >
        <FaTelegram />
      </SocialIcon>
    </SocialContainer>
  );
};

export default SocialLinks; 