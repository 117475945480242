import React from 'react';
import styled from 'styled-components';

// Define mediaQueries
const mediaQueries = {
  desktop: '@media (max-width: 1200px)',
  tablet: '@media (max-width: 768px)',
  mobile: '@media (max-width: 480px)',
};

const Container = styled.div`
  padding: 4rem 2rem;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  font-family: 'Press Start 2P', cursive;
  box-sizing: border-box;

  ${mediaQueries.tablet} {
    padding: 3rem 1.5rem;
  }

  ${mediaQueries.mobile} {
    padding: 2rem 1rem;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3rem;
  background: linear-gradient(90deg, #FF6B6B, #4ECDC4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${mediaQueries.tablet} {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  ${mediaQueries.mobile} {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const ProposalGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  width: 80%;
  margin: 0 auto;
  height: auto;
  min-height: 70%;

  ${mediaQueries.tablet} {
    width: 90%;
    gap: 1.5rem;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;

const ProposalCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 1.5rem;
  transition: transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;

  ${mediaQueries.tablet} {
    padding: 1.25rem;
  }

  ${mediaQueries.mobile} {
    padding: 1rem;
  }

  &:hover {
    transform: translateY(-5px);
  }

  @media (hover: none) {
    &:hover {
      transform: none;
    }
  }
`;

const ProposalStatus = styled.div`
  display: inline-block;
  padding: 0.25rem 1rem;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 1rem;
  background: ${props => props.active ? 'rgba(46, 213, 115, 0.15)' : 'rgba(255, 71, 87, 0.15)'};
  color: ${props => props.active ? '#2ed573' : '#ff4757'};
`;

const ProposalTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #000000;

  ${mediaQueries.tablet} {
    font-size: 1.1rem;
  }

  ${mediaQueries.mobile} {
    font-size: 1rem;
  }
`;

const ProposalDescription = styled.p`
  font-size: 0.875rem;
  margin-bottom: 1rem;
  color: #000000;
  line-height: 1.5;

  ${mediaQueries.mobile} {
    font-size: 0.8rem;
  }
`;

const ProposalInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  color: black;
  font-size: 0.875rem;

  ${mediaQueries.mobile} {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    font-size: 0.8rem;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 6px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  margin-bottom: 1rem;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    width: ${props => props.progress}%;
    height: 100%;
    background: linear-gradient(90deg, #4ECDC4, #2ed573);
    border-radius: 3px;
  }
`;

const VoteButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 8px;
  background: linear-gradient(90deg, #4ECDC4, #2ed573);
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.2s ease;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.9rem;

  ${mediaQueries.tablet} {
    padding: 0.6rem;
    font-size: 0.8rem;
  }

  ${mediaQueries.mobile} {
    padding: 0.5rem;
    font-size: 0.7rem;
  }

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 3rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  color: black;
  font-size: 1.2rem;
  margin: 0 auto;
  width: 80%;
  max-width: 600px;

  ${mediaQueries.tablet} {
    width: 90%;
    padding: 2rem;
    font-size: 1.1rem;
  }

  ${mediaQueries.mobile} {
    width: 95%;
    padding: 1.5rem;
    font-size: 1rem;
  }
`;

const ClosedVote = () => {
  const proposals = [];

  return (
    <Container>
      <Title>Ended Proposals</Title>
      {proposals.length > 0 ? (
        <ProposalGrid>
          {proposals.map(proposal => (
            <ProposalCard key={proposal.id}>
              <ProposalStatus active={proposal.status === 'active'}>
                {proposal.status === 'active' ? 'Active' : 'Ended'}
              </ProposalStatus>
              <ProposalTitle>{proposal.title}</ProposalTitle>
              <ProposalDescription>{proposal.description}</ProposalDescription>
              <ProposalInfo>
                <span>End Date: {proposal.endDate}</span>
                <span>{proposal.votes} votes</span>
              </ProposalInfo>
              <ProgressBar progress={proposal.progress} />
              <VoteButton disabled={proposal.status !== 'active'}>
                {proposal.status === 'active' ? 'Connect Wallet & Cast Vote' : 'Ended'}
              </VoteButton>
            </ProposalCard>
          ))}
        </ProposalGrid>
      ) : (
        <EmptyState>
          No closed proposals yet
        </EmptyState>
      )}
    </Container>
  );
};

export default ClosedVote;
